
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import i18n from "../../i18n";

@Component
export default class MecInput extends Vue {
  @Prop(String) public readonly label!: string;
  @Prop(String) public readonly type!: string;
  @Prop(String) public readonly name!: string;
  @Prop({ type: Boolean, default: true })
  public readonly autocomplete!: boolean;
  @Prop(String) public readonly icon!: string;
  @Prop({ type: Boolean, default: false }) public readonly required!: boolean;
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
  @Prop(String) public value!: string;
  @Prop(String) public placeholder!: string;
  @Prop(Function) public validate!: (v: string) => boolean;
  @Prop(String) public customErrorMessage!: string;
  @Prop(Boolean) public err!: boolean;
  @Prop(String) public errMsg!: string;
  @Prop(String) public classes!: string;
  @Prop(Boolean) public autofocus!: boolean;
  public isActive: boolean = false;
  public hasError: boolean = false;
  public errorMsg?: string;
  public model: string = "";
  public pristine: boolean = true; // Has the input field ever been modified.
  private allowErrorMessages: boolean = false;

  /**
   * Input type "File" doesnt support model bindings
   */
  public changeFileModel(event: any): void {
    this.model = event.target.files[0];
  }

  public validateModel() {
    console.log("validating", i18n.locale);
    if (this.err) {
      // Outside override of validation, f.ex when validating against an api
      this.hasError = true;
      this.errorMsg = this.errMsg;
      return;
    }
    if (this.required && !this.model) {
      this.hasError = true;
      this.errorMsg = this.$t("mec_input:error_required_field", i18n.locale).toString();
      return;
    }

    if (this.validate && this.model) {
      const valid: boolean = this.validate(this.model);
      if (!valid) {
        this.hasError = true;
        this.errorMsg = this.customErrorMessage
          ? this.customErrorMessage
          : this.$t("mec_input:error_invalid_format", i18n.locale).toString();
        return;
      }
    }

    this.hasError = false;
  }
  public onFocus() {
    this.isActive = true;
    this.$emit("hasFocused");
  }
  public onBlur() {
    this.isActive = false;
    this.allowErrorMessages = true;
    this.validateModel();
    this.$emit("hasBlurred");
  }
  public onEnter() {
    this.$emit("onEnter");
    this.allowErrorMessages = true;
  }
  public forceValidate() {
    this.allowErrorMessages = true;
    this.validateModel();
  }
  @Watch("model")
  public onValueChanged(val: string, oldVal: string) {
    this.pristine = false;
    if(this.type === "file") this.allowErrorMessages = true;
    this.validateModel();
    this.$emit("changed", { value: val, hasError: this.hasError });
  }
  @Watch("hasError")
  public onHasErrorChanged(val: boolean, oldVal: boolean) {
    this.$emit("hasErrorChanged", val);
  }
  @Watch("errMsg")
  public onErrMsgChanged(val: string, oldVal: string) {
    this.errorMsg = val;
  }

  @Watch("i18n")
  public localeChanged() {
    this.validateModel();
  }

  @Watch("err")
  public onErrChanged(val: boolean, oldVal: boolean) {
    this.hasError = val;
    this.validateModel();
  }

  public beforeCreated() {
    this.model = this.value;
  }
  public mounted() {
    document.addEventListener(("validate-all"), () => {
      this.forceValidate();
    })
    if (this.autofocus) {
      this.$nextTick(() => {
            const elem = (this.$refs[this.name] as HTMLElement);
            if (elem) { elem.focus();}
        });
    }
    if(this.value){
      this.model = this.value;
    }
    document.addEventListener("language-switched", () => {this.validateModel(); });
  }
}
