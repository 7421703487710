export class PnumSanitizer {
    /** Removes all spaces and dashes, if the pnum is 12 characters it skips the first 2. */
    public static TenCharPnum(pnum: string): string {
      if(!pnum) return pnum;
        // Trim spaces and dashes.
      let trimmedPnum = pnum.replace(new RegExp("-", "g"), "").replace(new RegExp(" ", "g"), "");

      // Trim to 10 chars, if it is 12 chars.
      trimmedPnum = trimmedPnum.length === 12 ? trimmedPnum.substr(2, 10) : trimmedPnum;

      return trimmedPnum;
    }
}
