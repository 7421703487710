import { PnumSanitizer } from "@/utilities/PnumSanitizer";

///
export class PnumValidator {
  /** Simple validator that only checks that first 6 are numbers and that there is total of 10. */
  public static validate(pnum: string): boolean {

    const trimmedPnum = PnumSanitizer.TenCharPnum(pnum);

    if (trimmedPnum.length !== 10) { return false; }

    const firstsix = trimmedPnum.substring(0, 6);
    const lastfour = trimmedPnum.substring(6, 10);

    // If first six are not numeric return false.
    if (!/^\d+$/.test(firstsix)) { return false; }

    // If last four are not numeric or 0000 return true since we need to allow  non numeric characters in last four.
    if (!/^\d+$/.test(lastfour) || lastfour === "0000") { return true; }

    const validSwedishPnum = PnumValidator.swedishPnumValidate(trimmedPnum);

    return validSwedishPnum;
  }
  /** Checks if pnum is numeric */
  public static validateNumeric(pnum: string): boolean {
    const sanitized = PnumSanitizer.TenCharPnum(pnum);
    const isNum = /^\d+$/.test(sanitized);
    if (!isNum) { return false; }

    return PnumValidator.validate(sanitized); // Only allow pure numbers to bankId;
  }
  public static swedishPnumValidate(input: string) {
    // Check valid length & form
    if (!input) { return false; }

    if (input.indexOf("-") === -1) {
        if (input.length === 10) {
            input = input.slice(0, 6) + "-" + input.slice(6);
        } else {
            input = input.slice(0, 8) + "-" + input.slice(8);
        }
    }
    if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) { return false };

    // Clean input
    input = input.replace("-", "");
    if (input.length === 12) {
        input = input.substring(2);
    }

    // Declare variables
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date(Number(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5)), Number((((!!RegExp.$2) ? Number(RegExp.$2) : Number(RegExp.$6)) - 1)), Number(((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)));
    let sum = 0;
    const numdigits = input.length;
    const parity = numdigits % 2;
    let i;
    let digit;

    // Check valid date
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;

    // Check luhn algorithm
    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(input.charAt(i), 10);
        if (i % 2 === parity) { digit *= 2; }
        if (digit > 9) { digit -= 9; }
        sum += digit;
    }
    return (sum % 10) === 0;
}
}
