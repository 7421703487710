import axios, { AxiosStatic } from "axios";
import * as crypto from "crypto";

export class BankidService {

  constructor(private baseURL: string, private requestVerificationToken: string, private axiosInstance: AxiosStatic) {

  }

  public async Initialize(pnum?: string, email?: string): Promise<BankidInitializeResponse> {
    const endpoint = this.baseURL + "/authenticate/bankid/initialize";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { personalnumber: pnum, email }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, bankIdReference: "", autoStartToken: "", qrSS:"",qrStartToken:"", isIos: false };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, bankIdReference: "", autoStartToken: "",qrSS:"",qrStartToken:"", isIos: false  };
    }
  }
  public async Poll(bankIdReference: string, pnum?: string, email?: string): Promise<BankidPollResponse> {
    const endpoint = this.baseURL + "/authenticate/bankid/poll";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { bankIdReference, personalnumber: pnum, email }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, authToken: "", completed: false };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, authToken: "", completed: false };
    }
  }
  public generateQRCode(qrStartToken:string, qrSS:string, time:number): string {
    let authCode = crypto.createHmac("sha256", qrSS).update(time.toString()).digest("hex").toString();
    let qrCode = `bankid.${qrStartToken}.${time.toString()}.${authCode}`
    return qrCode;
  }

}

interface BankidInitializeResponse {
  bankIdReference: string;
  autoStartToken: string;
  qrStartToken: string;
  qrSS: string;
  isIos: boolean;
  code: number;
  message: string;
}

interface BankidPollResponse {
    authToken: string;
    completed: boolean;
    code: number;
    message: string;
  }
