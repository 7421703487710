export class WindowService {
  /** Sends a postmessage to the opener window. Returns true if successfully sent. ReceivingUrl should be the url of the opener window, this function will extract the hostname of the url. */
  public static PostMessageToOpener(message: any, receivingUrl: string): boolean {

    try {
      if (window.opener) {
        window.opener.postMessage(message, receivingUrl);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
    return true;
  }

  private static extractHostname(url: string) {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    // find & remove port number
    hostname = hostname.split(":")[0];
    // find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
  }
}

export interface WindowPostMessage {
  messageType: string;
}
