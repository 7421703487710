import { EmailValidator } from "./EmailValidator";
import { PnumValidator } from "./PnumValidator";

export default class EmailOrPnumValidator {
    public static validate(value: string): boolean {
        if (EmailValidator.validate(value)) { return true; }

        if (PnumValidator.validate(value)) { return true; }

        return false;
    }
}
