import Vue from "vue";
import Router, { Route } from "vue-router";
import StartSweden from "./views/StartSweden.vue";
import PnumAuthentication from "@/views/PnumAuthentication.vue";
import PasswordLogin from "@/views/PasswordLogin.vue";
import LostPassword from "@/views/LostPassword.vue";
import LoginCodeSent from "@/views/LoginCodeSent.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import AccountInfoNeeded from "@/views/AccountInfoNeeded.vue";
import SeniorRegistration from "@/views/SeniorRegistration.vue";
import SchoolInfoNeeded from "@/views/SchoolInfoNeeded.vue";
import TermsAndCondition from "@/views/TermsAndCondition.vue";
import StudentTryingToAccessAlumni from "@/views/StudentTryingToAccessAlumni.vue";
import AlumniTryingToAccessStudent from "@/views/AlumniTryingToAccessStudent.vue";
import EarlyDispatchConfirm from "@/views/EarlyDispatchConfirm.vue";
import StudentApply from "@/views/StudentApply.vue";
import BankId from "@/views/BankId.vue";
import LogOut from "@/views/LogOut.vue";
import BecomeAlumni from "@/views/BecomeAlumni.vue";
import BecomeAlumniBankId from "@/views/BecomeAlumniBankId.vue";

import AutoLogin from "@/views/AutoLogin.vue";
import NoSwedishPnum from "@/views/NoSwedishPnum.vue";
import WhereIsMyStudentId from "@/views/WhereIsMyStudentId.vue";
import ConsentPage from "@/views/ConsentPage.vue";
import { Tracking } from "./tracking/tracking";
import { format } from "path";
Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location: any, onComplete?:any, onAbort?: any | undefined):void | any {
  if (onComplete || onAbort) return originalPush.call(this,location, onComplete, onAbort)
  return originalPush.call(this,location,()=>{},(err:Error)=>{
    if(err.message.indexOf('Redirected when going from') !== -1){
      
    // Ignore redirection errors threw by new vuejs router when pushing same route
    // The redirection error is caused by the "beforeEach" that keeps queryparams across all routes
    } 
    else throw err;
  });
}

/** We use this for routing names to make sure nothing gets broken if we change the routename */
export class Routes {
  public static PnumAuthenticationName = "PnumAuthentication";
  public static LostPasswordName = "LostPassword";
  public static LoginCodeSentName = "LoginCodeSent";
  public static AccountInfoNeededName = "AccountInfoNeeded";
  public static SchoolInfoNeededName = "SchoolInfoNeeded";
  public static ChangePasswordName = "ChangePassword";
  public static ChangePasswordPath = "/changepassword";
  public static StudentTryingToAccessAlumniName = "StudentTryingToAccessAlumni";
  public static AlumniTryingToAccessStudentName = "AlumniTryingToAccessStudent";
  public static BankIdName = "Bankid";
  public static BecomeAlumniName  = "BecomeAlumni";
  public static BecomeAlumniBankIdName = "BecomeAlumniBankId"
  public static NoSwedishPnumName = "NoSwedishPnum";
  public static WhereIsMyStudentIdName = "WhereIsMyStudentId";
  public static ConsentPageName = "ConsentPage";
  public static TermsAndConditionsName = "TermsAndCondition";
  public static EarlyDispatchConfirmName = "EarlyDispatchConfirm";
  public static SeniorRegistrationName = "SeniorRegistration";
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "StartSweden",
      component: StartSweden,
      meta: { title: "log_in" },
    },
    {
      path: "/pnumauth",
      name: Routes.PnumAuthenticationName,
      props: true,
      component: PnumAuthentication,
      meta: { title: "log_in" },
      beforeEnter: PnumAndMemberStatusGuard,
    },
    {
      path: "/login",
      name: "PasswordLogin",
      component: PasswordLogin,
      props: true,
      meta: { title: "log_in" },
      beforeEnter: PnumOrEmailGuard,
    },
    {
      path: "/lostpassword",
      name: Routes.LostPasswordName,
      props: true,
      component: LostPassword,
      beforeEnter: LostPasswordGuard,
      meta: { title: "log_in" },
    },
    {
      path: "/logincodesent",
      name: Routes.LoginCodeSentName,
      props: true,
      component: LoginCodeSent,
      beforeEnter: PnumOrEmailGuard,
      meta: { title: "log_in" },
    },
    {
      path: "/studentapply",
      name: "StudentApply",
      props: true,
      component: StudentApply,
      meta: { title: "log_in" },
    },
    {
      path: "/logout",
      alias: "/signout",
      name: "LogOut",
      props: true,
      component: LogOut,
      meta: { title: "log_in" },
    },
    {
      path: "/autologin",
      name: "Autologin",
      props: true,
      component: AutoLogin,
      meta: { title: "log_in" },
    },
    {
      path: "/bankid",
      name: Routes.BankIdName,
      props: true,
      component: BankId,
      meta: { title: "log_in" },
      beforeEnter: PnumGuard,
    },
    {
      path: "/accountinfoneeded",
      name: Routes.AccountInfoNeededName,
      props: true,
      component: AccountInfoNeeded,
      meta: { title: "log_in" },
      beforeEnter: AuthGuard,
    },
    {
      path: "/schoolinfoneeded",
      name: Routes.SchoolInfoNeededName,
      props: true,
      component: SchoolInfoNeeded,
      meta: { title: "log_in" },
      beforeEnter: AuthGuard,
    },
    {
      path: "/terms",
      name: Routes.TermsAndConditionsName,
      props: true,
      component: TermsAndCondition,
    },
    {
      path: Routes.ChangePasswordPath,
      name: Routes.ChangePasswordName,
      props: true,
      component: ChangePassword,
      beforeEnter: AuthGuard,
    },
    {
      path: "/studenttryingtoaccessalumni",
      name: Routes.StudentTryingToAccessAlumniName,
      props: true,
      component: StudentTryingToAccessAlumni,
      beforeEnter: AuthGuard,
    },
    {
      path: "/alumnitryingtoaccessstudent",
      name: Routes.AlumniTryingToAccessStudentName,
      props: true,
      component: AlumniTryingToAccessStudent,
      beforeEnter: AuthGuard,
    },
    {
      path: "/becomealumni",
      name: Routes.BecomeAlumniName,
      props: true,
      component: BecomeAlumni,
      beforeEnter: PnumOrEmailGuard,
    },
    {
      path: "/becomealumnibankid",
      name: Routes.BecomeAlumniBankIdName,
      props: true,
      component: BecomeAlumniBankId,
      beforeEnter: PnumOrEmailGuard,
    },
    {
      path: "/noswedishpnum",
      name: Routes.NoSwedishPnumName,
      props: true,
      component: NoSwedishPnum,
    },
    {
      path: "/whereismystudentid",
      name: Routes.WhereIsMyStudentIdName,
      props: true,
      component: WhereIsMyStudentId,
    },
    {
      path: "/consentpage",
      name: Routes.ConsentPageName,
      props: true,
      component: ConsentPage,
    },
    {
      path: "/earlydispatchconfirm",
      name: Routes.EarlyDispatchConfirmName,
      props: true,
      component: EarlyDispatchConfirm,
      beforeEnter: PnumGuard,
    },
    {
      path: "/seniorregistration",
      name: Routes.SeniorRegistrationName,
      props: true,
      component: SeniorRegistration,
      beforeEnter: SeniorGuard,
    }
  ],
});

function hasQueryParams(route: Route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  // Add existing queryparam to next page
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    const toWithQuery:any = Object.assign({}, to, { query: from.query });
    next(toWithQuery);
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to.path !==  from.path) {
    console.log("tracking currentpage ", to.path);
    Tracking.TrackCurrentPageView(to.name as string, to.path as string);
  }
});


function AuthGuard(to: Route, from: Route, next: any) {
  console.log("Guarding", to, from, next);
  if (!AuthenticatedUser.loggedIn) {
    next({
      name: "StartSweden",
      query: from.query,
    });
  } else {
    next();
  }
}
function SeniorGuard(to: Route, from: Route, next: any) {
  if (AuthFlowContext.memberType !== "senior") {
    next({
      name: "StartSweden",
      query: from.query,
    });
  } else {
    next();
  }
}
function LostPasswordGuard(from: Route, to: Route, next: any) {
      // make sure pnum or email or secondary email exists, if it doesnt redirect to start.
      if (!from.params.pnum && !from.params.email && !from.params.secondaryEmail) {
        next({
          name: "StartSweden",
          query: from.query,
        });
        return;
      }

      next();
}

function PnumOrEmailGuard(from: Route, to: Route, next: any) {
    // make sure pnum or email exists, if it doesnt redirect to start.
    if (!from.params.pnum && !from.params.email) {
      next({
        name: "StartSweden",
        query: from.query,
      });
      return;
    }

    next();
}

function PnumAndMemberStatusGuard(from: Route, to: Route, next: any) {
  // make sure pnum or email exists, if it doesnt redirect to start.
  if (!from.params.pnum && !from.params.memberStatus) {
    next({
      name: "StartSweden",
      query: from.query,
    });
    return;
  }

  next();
}
function PnumGuard(from: Route, to: Route, next: any) {
  // make sure pnum or email exists, if it doesnt redirect to start.
  if (!from.params.pnum) {
    next({
      name: "StartSweden",
      query: from.query,
    });
    return;
  }

  next();
}
export default router;
