import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=488b217e&scoped=true"
import script from "./AppHeader.vue?vue&type=script&lang=ts"
export * from "./AppHeader.vue?vue&type=script&lang=ts"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=488b217e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488b217e",
  null
  
)

/* custom blocks */
import block0 from "./AppHeader.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports