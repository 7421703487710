export class UrlHelper {
  /**
   * Adds query parameter to url
   */
  public static addParamToUrl(
    key: string,
    value: string,
    inputUrl: string,
  ): string {
    if (value == null || value === "") { return inputUrl; }

    // Separate the hash part from the base URL
    const hashIndex = inputUrl.indexOf('#');
    let url = hashIndex !== -1 ? inputUrl.substring(0, hashIndex) : inputUrl;
    const hash = hashIndex !== -1 ? inputUrl.substring(hashIndex) : '';
  
    // First remove the param if it exists
    url = UrlHelper.removeParamFromUrl(key, url); 
    
    // If url contains ? then append & else append ?
    const separator = url.indexOf("?") !== -1 ? "&" : "?";

    url += `${separator}${key}=${encodeURI(value)}`;
    return url+hash;
  }
  public static removeParamFromUrl(key: string, inputUrl: string): string {
    let url = inputUrl;
    const urlparts = url.split("?");
    if (urlparts.length >= 2) {
      const prefix = `${encodeURIComponent(key)}=`;
      const pars = urlparts[1].split(/[&;]/g);

      // reverse iteration as may be destructive
      for (let i = pars.length; i-- > 0;) {
        // idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }
      const urlpart2 = pars.length > 0 ? `?${pars.join("&")}` : "";
      url = `${urlparts[0]}${urlpart2}`;
      return url;
    }
    return url;
  }
  /**
   * Gets queryparameter from url
   */
  public static getParamFromUrl(inputKey: string, url: string): string {
    let key = inputKey;
    key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${key}=([^&#]*)`);
    const results = regex.exec(url);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  public static getBoolParam(key: string, url: string): boolean {
    const value = UrlHelper.getParamFromUrl(key, url);
    if (!value) { return false; }

    return value.toLowerCase() === "true";
  }
  /** Makes sure that the url ends with a slash */
  public static ensureTrailingSlash(inputUrl: string): string {
    let url = inputUrl;
    const lastChar = url.substr(-1); // Selects the last character
    if (lastChar !== "/") {
      // If the last character is not a slash
      url = `${url}/`; // Append a slash to it.
    }
    return url;
  }
}
