
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import { Authorize } from "../authorize";
import { SelectBoxElement } from "@/components/forms/selectboxelement";
import SelectBox from "@/components/forms/SelectBox.vue";
import ActionButton from "@/components/forms/ActionButton.vue";

@Component({
  components: {
    InfoBlock,
    MecenatLogo,
    SelectBox,
    ActionButton,
  },
})
export default class SchoolInfoNeeded extends Vue {

 /** School id of latest card. 200 = Highschool */
 @Prop(Number) public schoolId!: number;
 @Prop(Number) public subjectId?: number;
 @Prop(Number) public graduationYear?: number;
 @Prop(Boolean) public IsFieldOfStudyOutOfDate!: boolean;
 public pickedSubject: number = 0;
 public pickedGraduationYear: number = 0;
 public skipVisible: boolean = true;


public async continueClick() {
  this.skipVisible = false;
  AuthFlowContext.skipSchoolInformation = true;
  const updateRespo = await UserService.UpdateSchoolInfo(AuthenticatedUser.uid as string, this.pickedGraduationYear, this.pickedSubject);
  gtag("event", "schoolinfo submit" , {event_category: "schoolinfo", event_label: this.schoolId === 200 ? "highschool" : "other education"});
  const authResp = await Authorize.redirectFlow(AuthFlowContext, AuthenticatedUser.rawJwt as string);
  this.$router.push({ name: authResp.internalRedirect as string, params: authResp.internalRedirectProps });
}
 public async subjectPicked(subjectValue: number) {
   this.pickedSubject = subjectValue;
   if (this.pickedSubject && this.pickedGraduationYear) {
      this.continueClick();
   }
 }
 public async skipSchoolInfo() {
    this.skipVisible = false;
    gtag("event", "schoolinfo skipped" , {event_category: "schoolinfo", event_label: this.schoolId === 200 ? "highschool" : "other education"});
    AuthFlowContext.skipSchoolInformation = true;
    const authResp = await Authorize.redirectFlow(AuthFlowContext, AuthenticatedUser.rawJwt as string);
    this.$router.push({ name: authResp.internalRedirect as string, params: authResp.internalRedirectProps });
 }
 public async graduationYearPicked(graduationValue: number) {
    this.pickedGraduationYear = graduationValue;
    if (this.pickedSubject && this.pickedGraduationYear) {
        this.continueClick();
   }
 }


 public availableGraduationYears() {
   const yearNow = new Date().getFullYear();
   const uni: number[] = [yearNow, yearNow + 1, yearNow + 2, yearNow + 3, yearNow + 4, yearNow + 5];
   const highschool: number [] = [yearNow, yearNow + 1, yearNow + 2, yearNow + 3];

   const pickText = this.schoolId === 200 ? this.$t("pick-exam-year-highschool").toString() : this.$t("pick-exam-year").toString();
   const graduationYears = this.schoolId === 200 ? highschool : uni;
   const elements = [
              {value: "", text: pickText, disabled: true, selected: true},
            ];
   for (const year of graduationYears) {
    elements.push({value: String(year), text: String(year),  disabled: false, selected: false});
   }
   return elements;
 }

 public availableUniversitySubjects(): SelectBoxElement[] {
    let subjects: SelectBoxElement[] = [
              {value: "", text: this.$t("pick-subject").toString(), disabled: true, selected: true},
              {value: 7, text: this.$t("study-subject-cs").toString()},
              {value: 39, text: this.$t("study-subject-veterinary").toString()},
              {value: 1, text: this.$t("study-subject-economy").toString()},
              {value: 11, text: this.$t("study-subject-humanities").toString()},
              {value: 10, text: this.$t("study-subject-health").toString()},
              {value: 42, text: this.$t("study-subject-journalism").toString()},
              {value: 2, text: this.$t("study-subject-law").toString()},
              {value: 5, text: this.$t("study-subject-art").toString()},
              {value: 13, text: this.$t("study-subject-agriculture").toString()},
              {value: 44, text: this.$t("study-subject-math").toString()},
              {value: 45, text: this.$t("study-subject-materials").toString()},
              {value: 12, text: this.$t("study-subject-science").toString()},
              {value: 8, text: this.$t("study-subject-education").toString()},
              {value: 46, text: this.$t("study-subject-religion").toString()},
              {value: 3, text: this.$t("study-subject-society").toString()},
              {value: 9, text: this.$t("study-subject-social").toString()},
              {value: 47, text: this.$t("study-subject-language").toString()},
              {value: 6, text: this.$t("study-subject-technology").toString()},
              {value: 18, text: this.$t("other").toString()},
            ];
    subjects = this.sortSubjects(subjects);

    return subjects;
  }

  public availableHighSchoolSubjects(): SelectBoxElement[]  {
    let subjects: SelectBoxElement[] = [
              {value: "", text: this.$t("pick-subject").toString(), disabled: true, selected: true},
              {value: 20, text: "Bygg- och anläggningsprogrammet (BA)"},
              {value: 21, text: "Barn- och fritidsprogrammet (BF)"},
              {value: 22, text: "El- och energiprogrammet (EE)"},
              {value: 23, text: "Ekonomiprogrammet (EK)"},
              {value: 24, text: "Estetiska programmet (ES)"},
              {value: 25, text: "Fordons- och transportprogrammet (FT)"},
              {value: 26, text: "Handels- och administrationsprogrammet (HA)"},
              {value: 27, text: "Hotell- och Turismprogrammet (HT)"},
              {value: 28, text: "Humanistiska programmet (HU)"},
              {value: 29, text: "Hantverksprogrammet (HV)"},
              {value: 30, text: "Industritekniska programmet (IN)"},
              {value: 31, text: "Naturbruksprogrammet (NB)"},
              {value: 32, text: "Restaurang- och livsmedelsprogrammet (RL)"},
              {value: 33, text: "Samhällsvetenskapsprogrammet (SA)"},
              {value: 34, text: "Teknikprogrammet (TE)"},
              {value: 35, text: "VVS- och fastighetsprogrammet (VF)"},
              {value: 36, text: "Naturvetenskapsprogrammet (NA)"},
              {value: 37, text: "Vård- och omsorgsprogrammet (VO)"},
              {value: 38, text: "Annat/Övrigt" },
            ];
    subjects = this.sortSubjects(subjects);
    return subjects;
  }
  public sortSubjects(array: SelectBoxElement[]): SelectBoxElement[]  {
    return array.sort((a, b) => {
      if (a.value === "") { return -1; }
      if (b.value === "") { return 1; }
      if (a.value === 38 || a.value === 18) { return 100; }

      if (a.text < b.text) { return -1; }
      if (a.text > b.text) { return 1; }

      return 0;
      } );
}


}



