
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import MecInput from "@/components/forms/MecInput.vue";
import ActionButton from "@/components/forms/ActionButton.vue";
import { Authorize } from '@/authorize';
@Component({
  components: {
    InfoBlock,
    MecenatLogo,
    MecInput,
    ActionButton
  },
})
export default class EarlyDispatchConfirm extends Vue {

    @Prop(String) public pnum!: string;
    @Prop(String) public acceptTermsText!: string;
    private maskedFname: string = "";
    private maskedLname: string = "";
    private submitting: boolean = false;
    private isLoading: boolean = true;
    private isNotFound: boolean = false;
    async created(){

      let resp = await AuthService.AuthenticateWithEarlyDispatch(this.pnum, false, this.acceptTermsText);
      if(resp.code == 404) {
        this.isNotFound = true;
        this.isLoading = false;
      } 
      else if(resp.code != 200) {
        throw new Error(`Unhandled response from EarlyDispatchConfirm created ${resp.code} ${this.pnum} ${resp.message}`);
      } 
      else {
      this.isLoading = false; 

      this.maskedFname = resp.firstnameMasked;
      this.maskedLname = resp.lastnameMasked;
      }
      

    }
    async continueClick() {
      this.submitting = true;
      let response = await AuthService.AuthenticateWithEarlyDispatch(this.pnum, true, this.acceptTermsText);
      
      if(response.code < 300) {
        const resp = await Authorize.redirectFlow(AuthFlowContext, response.authToken);
        this.$router.push({ name: resp.internalRedirect as string, params: resp.internalRedirectProps });
        return;
      } else throw new Error(`Unhandled response from EarlyDispatchConfirm coatinue ${response.code} ${response.message} ${this.pnum}`);
      this.submitting = false;
    }
}
