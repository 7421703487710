
import { Prop, Vue, Component } from "vue-property-decorator";
@Component({

})
export default class ActionButton extends Vue {
  // tslint:disable-next-line:ban-types
  @Prop(Boolean) public disabled: boolean | undefined;
  @Prop(String) public text: string | undefined;
  @Prop(Boolean) public submitting?: boolean;
  private memberType:string ="";
  private continueBtn() {
    this.$emit("click");
  }
  get btnText() {
    if (this.submitting) return "";
    if (this.text) { return this.text; } else { return this.$i18n.t("continue", this.$i18n.locale); }
  }
  get btnStyle(){
    var bgColor = "linear-gradient(180deg, #11AFD1 0%, #109DBC 100%);";
    var fontColor = "fff";
    var borderColor ="#10A5C5";
    var fontWeight = "400";
		if(AuthFlowContext.memberType === "senior"){
			bgColor = "#95C7CB";
      borderColor = "#95C7CB";
      fontColor = "rgb(34, 34, 34) !important";
      fontWeight ="600";
		}
		return {
      background: bgColor,
      color: fontColor,
      borderColor: borderColor,
      fontWeight: fontWeight,
  }
  }
  get isSenior(){
			return this.memberType == "senior";
		}

		get isAlumni(){
			return this.memberType == "alumni";
		}  
}
