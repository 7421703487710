
	import { Component, Prop, Vue } from "vue-property-decorator";
	import * as Cookie from "js-cookie";
	import i18n from "@/i18n";
	import { Routes } from "../../router";
	import { GoogleAnalytics } from "@/tracking/googleanalytics";
	
	@Component({
		components: {
		},
	})
	export default class AppHeader extends Vue {
		private languages = Object.keys(i18n.messages); // Gets all languages we have support for.
		private isSenior:boolean = false;
		private memberType:string ="";
		get activeLang() {
			return  i18n.locale;
		}
		private languageSwitcherVisible = false;
		public created(){
		  this.isSenior = AuthFlowContext.memberType == "senior";
		}
		public toggleLanguageSwitcher(ev: any) {
			this.languageSwitcherVisible = !this.languageSwitcherVisible;
		}
		public back() {
			this.$router.back();
		}
		public selectLang(language: string) {
	
			i18n.locale = language;
			this.languageSwitcherVisible = false;
			Cookie.set("lang", i18n.locale);
	
			const event = new Event("language-switched");
			document.dispatchEvent(event);
			// this.$router.go(0);
		}
	
		get backActive() {
			if (this.$route.name === "StartSweden" || this.$route.name === Routes.ChangePasswordName) {
				return false;
			}
			return true;
		}
			get isAlumni(){
				return this.memberType == "alumni";
			} 
	}
	