export class UserContext {
      public loggedIn: boolean;
      public email?: string;
      public uid?: string;

      public legacyId?: number;
      /** Unix time seconds */
      public verificationValidTo?: string;
      /** User if regular user, Student if student */
      public role?: MemberStatus;
      public rawJwt?: string;
    constructor(jwt?: string) {
        this.loggedIn = false;
        if (jwt) {
          this.rawJwt = jwt;
          this.loggedIn = true;
          const encodedPayload = jwt.split(".")[1];
          const payload: MecenatIdPayload = JSON.parse(atob(encodedPayload));
          const hasEmail = (payload.name as string).indexOf("@") !== -1;
          this.email = hasEmail ? payload.name : undefined;
          this.uid = payload.uid;
          this.legacyId = payload.legacyId;
          this.verificationValidTo = payload.verificationValidTo;
          this.role = (payload.role as string).toLowerCase() as MemberStatus;
        }
      }
}
export type MemberStatus =
    "unknown" | // We don't know anything about this pnum
    "other" | // User is neither a student nor alumn, but exists in our db.
    "member" | // Not a student not an alumn
    "student" | // We know this pnum is a student but he/she has never verified with mecenat before.
    "alumni" | // This person has a valid alumni card
    "senior" | // This person is a valid senior
    "earlydispatch" | // This person doesnt exist in db, but is valid for an earlydispatch card (Tidigt utskick / spar till blivande gymnasieelever)
    "studentoralumni" | // This person is a student or an alumni, used when we don't want to reveal status in responses.
    "invalid"; // Invalid request

  /** Payload of our mecenatid jwt */
  // tslint:disable-next-line:max-classes-per-file
export class MecenatIdPayload {
    public sub?: string;
    // Email address. Needs to be called name because of jwt standards
    public name?: string;

    public legacyId?: number;
    public uid?: string;

    // Unix time
    public verificationValidTo?: string;
    public role?: string;
    public exp?: number;
  }
