import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { UrlHelper } from "./utilities/UrlHelper";

Vue.use(VueI18n);
let initLang = UrlHelper.getParamFromUrl("lang", window.location.href);
function loadLocaleMessages(): LocaleMessages {
  // const locales = require.context('./localization/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  // const messages: LocaleMessages = {};
  // locales.keys().forEach((key) => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1];
  //     messages[locale] = locales(key);
  //   }
  // });
  // return messages;

  // The uncommented code above automatically retrieves all json files in locale, but it doesnt work with jest-testing.
  const messages: LocaleMessages = {};
  messages.sv = require("@/localization/locales/sv.json");
  messages.en = require("@/localization/locales/en.json");
  return messages;
}

export default new VueI18n({
  locale: initLang || GlobalEnvironment.initLocale || "sv",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sv",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});
