
import { Component, Vue } from "vue-property-decorator";
import AppHeader from "@/components/layout/AppHeader.vue";
import AppContent from "@/components/layout/AppContent.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import LoaderSmall from "@/views/LoaderSmall.vue";
import { UrlHelper } from "./utilities/UrlHelper";

import {Authorize} from "@/authorize";
import "@/assets/style/core.scss";

@Component({
  components: {
    AppHeader,
    AppContent,
    LoaderSmall,
    MecenatLogo,
  },
})
export default class App extends Vue {
  private loading: boolean = true;
  private status: string = "";
	private memberType:string ="";
	private appLoginChallenge:string = "";
	private showAppLoginChallenge:boolean = false;
  private async created() {
		this.memberType = AuthFlowContext.memberType;
		this.appLoginChallenge = UrlHelper.getParamFromUrl("login_challenge", AuthFlowContext.redirectUrl);

		if (this.memberType == "senior") {
        document.body.classList.add (
          'senior'
        );
    }
	if (this.memberType == "alumni") {
        document.body.classList.add (
          'alumni'
        );
      }
	  
    // Only use loader if its the first view
    if (this.$route.name !== "StartSweden") {
        this.loading = false;
				this.showAppLoginChallenge = false;
        return;
    }
    gtag("event", "started" , {event_category: "flow", event_label: "1"});
    setTimeout(() => { this.loading = false; }, 9000); // just incase something goes wrong after 7 seconds we display the first page for a chance to restart the flow.
    this.status = this.$t("checking-student-status", this.$i18n.locale,{membertype:this.memberType}).toString();

    if (AuthenticatedUser.loggedIn) {
        console.log("Authed");
        const authResponse = await Authorize.redirectFlow(AuthFlowContext, AuthenticatedUser.rawJwt as string);
        console.log(authResponse);

        if (authResponse && !authResponse.valid && authResponse.internalRedirect) {
            console.log("pushing to", authResponse.internalRedirect);
            this.$router.push({name: authResponse.internalRedirect as string, params: authResponse.internalRedirectProps});
            this.loading = false;
        }
    } else {
        this.loading = false;
    }

	
  }

    private get showSupport() {
        const shouldShowSupport = (this.$route.name !== "TermsAndCondition" && this.$route.name !== "BecomeAlumni" && this.$route.name !== "StudentApply");

        return shouldShowSupport;
    }
		get isSenior(){
			return this.memberType == "senior";
		}

		get isAlumni(){
			return this.memberType == "alumni";
		}  
}
