
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import { UserContext } from "@/context/UserContext";
import { Routes } from "@/router";
import MecInput from "@/components/forms/MecInput.vue";
import ActionButton from "@/components/forms/ActionButton.vue";
import { Authorize } from "@/authorize";
@Component({
  components: {
    InfoBlock,
    MecenatLogo,
    MecInput,
    ActionButton,
  },
})
export default class LoginCodeSent extends Vue {

  @Prop()
  public email!: string;

  private loginCode: string = "";
  private codeHasError: boolean = false;
  private submitting: boolean = false;
  public async created() {
      this.requestLoginCode();
  }
  public async requestLoginCode() {
      const callbackUrl = window.location.origin + "/2";
      const response = await UserService.RequestLoginCode(callbackUrl, this.$i18n.locale, this.email, AuthFlowContext);
  }

  public async continueClick() {
    this.submitting = true;
     const response = await UserService.ValidateLoginCode(this.loginCode, AuthFlowContext,this.email);
     if (response.code < 300) {
      AuthenticatedUser = new UserContext(response.authToken);
      const resp = await Authorize.redirectFlow(AuthFlowContext, response.authToken);

      if (resp.internalRedirect) {
          this.$router.push({name: resp.internalRedirect, params: resp.internalRedirectProps});
      }
      return;
    } else if (response.code < 500 && response.code > 399) {
      this.codeHasError = true;
    } else throw new Error(`Unhandled response ${response.code} ${this.email} ${this.loginCode}`);
    this.submitting = false;
  }
}
