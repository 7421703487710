
import { Component, Vue, Prop } from "vue-property-decorator";
import jsCookie from "js-cookie";
import { UserContext } from "../context/UserContext";
@Component({
  components: {

  },
})
export default class AutoLogin extends Vue {
    public beforeCreate() {
        const mecenatId = this.$route.query.mecenat_id;
        if (mecenatId) {
            jsCookie.set("mecenat.id", mecenatId);
        }
        const redirect = this.$route.query.redirect;
        if (redirect) {
            window.location.href = redirect as string;
        }
    }
}
