
import { Prop, Vue, Component, Watch } from "vue-property-decorator";
import { SelectBoxElement } from "./selectboxelement";
@Component({

})
export default class SelectBox extends Vue {
  // tslint:disable-next-line:ban-types
  @Prop(Boolean) public disabled: boolean | undefined;
  @Prop(Array) public elements!: SelectBoxElement[];
  @Prop(Function) public sorter: boolean | undefined;
  public selected: string = "";

  @Watch("selected")
  public onSelectedChanged(val: string, oldVal: string) {
      this.$emit("changed", val);
  }
}


