import {DirectiveOptions} from "vue";
import { UrlHelper } from "@/utilities/UrlHelper";

const directive: DirectiveOptions = {
    async bind(el: HTMLElement, binding, vnode) {
      const hrefAttr = el.getAttribute("href");
      const cmd = binding.value;
      if (!hrefAttr || !AuthFlowContext.isApp || !cmd) { return; }

      const newHref = UrlHelper.addParamToUrl("appcmd", cmd, hrefAttr);
      el.setAttribute("href", newHref);
    },
    async update(el: HTMLElement, binding, vnode) {
      const hrefAttr = el.getAttribute("href");
      const cmd = binding.arg;

      if (!hrefAttr || !AuthFlowContext.isApp || !cmd) { return; }

      const newHref = UrlHelper.addParamToUrl("appcmd", cmd, hrefAttr);
      el.setAttribute("href", newHref);
    },
  };
export default directive;
