
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
@Component({
  components: {
    InfoBlock,
    MecenatLogo,
  },
})
export default class WhereIsMyStudentId extends Vue {
    @Prop(String) public pnum!: string;
    public back() {
      this.$router.push({ name: "PnumAuthentication", params: { pnum: this.pnum } });
    }
}
