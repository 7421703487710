
import { Component, Vue, Prop } from "vue-property-decorator";
import jsCookie from "js-cookie";
import { UserContext } from "../context/UserContext";
import { UrlHelper } from "@/utilities/UrlHelper";
@Component({
  components: {

  },
})
export default class LogOut extends Vue {
    public beforeCreate() {
        const temp = location.host.split(".").reverse();
        const rootDomain = "." + temp[1] + "." + temp[0];
        const rootCookieAttrs: jsCookie.CookieAttributes = {
    domain: rootDomain,
    };
        jsCookie.remove("mecenat.id", rootCookieAttrs);
        jsCookie.remove("mecenat.id");
        jsCookie.remove("mecenat.legacy.uid");
        AuthenticatedUser = new UserContext();
        let logoutRedirect = UrlHelper.getParamFromUrl("logout_redirect", window.location.href);
        if(logoutRedirect){
            window.location.href = logoutRedirect;
            return;
        }
        this.$router.push({name: "StartSweden"});
    }
}
