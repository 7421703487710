import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";
import i18n from "./i18n";
import cdndirective from "@/directives/cdn-directive";
import autofocusdirective from "@/directives/autofocus-directive";
import appcmddirective from "@/directives/appcmd-directive";

import * as Cookie from "js-cookie";
import { MockUserService } from "./services/user/mock-user-service";
import { MockAuthService } from "./services/auth/mock-auth-service";
import { AuthFlowContext } from "./context/AuthFlowContext";
import { UserService } from "@/services/user/user-service";
import { AuthService } from "@/services/auth/auth-service";
import {BankidService} from "@/services/bankid/bankid-service";
import Axios from "axios";
import { UserContext } from "./context/UserContext";
import { VueError, JsError } from "./services/error-handling/error-handling";
import { UrlHelper } from "./utilities/UrlHelper";
import { Tracking } from "./tracking/tracking";

(window as any).AuthFlowContext = new AuthFlowContext();
Tracking.Init();
const authCookie = Cookie.get("mecenat.id");
console.log("authCookie", authCookie);
const authParam = UrlHelper.getParamFromUrl("mecenat_id", window.location.href);
if (authCookie || authParam) {
  (window as any).AuthenticatedUser = authCookie ? new UserContext(authCookie) : new UserContext(authParam);
} else {
  (window as any).AuthenticatedUser = new UserContext();
}

// Internet explorer doesnt work with arrow functions here
// tslint:disable-next-line:only-arrow-functions
(window as any).ValidateAll = function () {
  const validateAllEvent = new CustomEvent("validate-all", {});
  document.dispatchEvent(validateAllEvent);
};

// Setup Services.
// @ts-ignore
const xsrfToken = document.getElementById("__RequestVerificationToken").value;

// let initMock = MockUserService.GetMock(xsrfToken, null);
// let mockAdapter = initMock[1];
// let userServiceMock = initMock[0];
// let authServiceMock = MockAuthService.GetMock(xsrfToken, mockAdapter)[0];

(window as any).UserService = new UserService("/2/api", xsrfToken, Axios);
(window as any).AuthService = new AuthService("/2/api", xsrfToken, Axios);
(window as any).BankidService = new BankidService("/2/api", xsrfToken, Axios);

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
  VueError(err, vm, info);
};
window.onerror = JsError;

Vue.config.productionTip = false;
Vue.directive("cdn", cdndirective);
Vue.directive("focus", autofocusdirective);
Vue.directive("appcmd", appcmddirective);
router.beforeEach((to:any, from, next) => {
  const lang = Cookie.get("lang");
  if (lang) {
    i18n.locale = lang;
  }
  to.meta.title = i18n.t(to.meta.title).toString();
  document.title = to.meta.title;
  next();
});

new Vue({
  router,
   i18n,
   store,
  render: (h) => h(App),
}).$mount("#app");

const errorTest = UrlHelper.getParamFromUrl("throw_error", window.location.href);
if (errorTest) {
  (window as any).errorFromMainTs();
}

