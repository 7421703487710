
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import ActionButton from "@/components/forms/ActionButton.vue";

import {EmailValidator} from "@/validation/EmailValidator";
import {PasswordValidator} from "@/validation/PasswordValidator";
import {MobilePhoneValidator} from "@/validation/MobilePhoneValidator";
import { Authorize } from "../authorize";
import { FacebookPixel } from "../utilities/FacebookPixel";

@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,
  },
})
export default class SeniorRegistration extends Vue {
  @Prop(String) public email!: string;
  @Prop(String) public pnum!:string;
  public showPasswordTip = false;
  private inputEmail: string = "";
  private memberType: string ="";
  private emailValidate = EmailValidator.validate;
  private passwordValidate = PasswordValidator.validate;
  private mobilePhoneValidate = MobilePhoneValidator.validate;
  private confirmed:boolean = false;
  private inputPassword: string = "";
  private inputPhoneMobile: string ="";
  private maskedFname: string = "";
  private maskedLname: string = "";
  private submitting: boolean = false;
  private passwordVisible: boolean = false;
  async created() {
    //FacebookPixel.fbPixelInit();
    this.memberType = AuthFlowContext.memberType;
    this.inputEmail = this.email;
    const resp = await UserService.GetMaskedName(this.pnum);
    if (resp.code < 299) {
      this.$nextTick(() => {
      this.maskedFname = resp.maskedFname as string;
      this.maskedLname = resp.maskedLname as string;
      });
    } else{ throw new Error(`Unhandled response ${resp.code} : ${resp.message} ${AuthenticatedUser.uid} ${this.inputEmail}`);}

  }

  public async continueClick() {
    if(!this.confirmed){
      this.confirmed = true;
      return;
    }
    if (!this.isFormValid) return;
    this.submitting = true;
    const resp = await UserService.RegisterSenior(this.pnum, this.email, this.inputPassword, AuthFlowContext.referringSource as string, this.inputPhoneMobile, AuthFlowContext.referringCode as string);
    if (resp.code < 299) {
      //FacebookPixel.fbPixelUserActivated();
      //gtag('event', 'conversion', {
      //'send_to': 'AW-356733280/NEAYCP6BpOoCEOCijaoB' });
      const authResp = await Authorize.redirectFlow(AuthFlowContext, resp.authToken);
      this.$router.push({ name: authResp.internalRedirect as string, params: authResp.internalRedirectProps });
      return;
    } else if(resp.code === 409){

      this.submitting = false;
    } else throw new Error(`Unhandled response ${resp.code} : ${resp.message} ${AuthenticatedUser.uid} ${this.inputEmail}`);
    this.submitting = false;
  }
  public togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  public focusPassword() {
    const element = document.getElementById("password") as HTMLElement;
    element.focus();
  }
  public isFormValid() {
    if (this.emailValidate(this.inputEmail) && this.passwordValidate(this.inputPassword) && (this.inputPhoneMobile === "" || this.mobilePhoneValidate(this.inputPhoneMobile))) {
      return true;
    }
    return false;
  }

}
