
import { Component, Vue } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import SocialLogin from "@/components/SocialLogin.vue";
import EmailOrPnumValidator from "@/validation/EmailOrPnumValidator";
import { EmailValidator } from "@/validation/EmailValidator";
import { PnumValidator } from "@/validation/PnumValidator";

import MecenatLogo from "@/components/MecenatLogo.vue";
import { UserContext } from "../context/UserContext";
import { Authorize } from "../authorize";
import ActionButton from "@/components/forms/ActionButton.vue";
import {Routes} from "@/router";
import { PnumSanitizer } from "../utilities/PnumSanitizer";
import { SeniorValidator } from "@/validation/SeniorValidator";
@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,
    SocialLogin,
    MecenatLogo,
  },
})
export default class StartSweden extends Vue {

  public input!: string;
  public hasError: boolean = false;
  public errorMessage: string = "";
  public validate: (v: string) => boolean = EmailOrPnumValidator.validate;
  public pnumValidate: (v: string) => boolean = PnumValidator.validate;
  public isFormValid: boolean = false;
  public email: string = "";
  /** Forces user to enter pnum if we can't find email in db */
  public forcePnum: boolean = false;
  /** Saves the entered email so we can pass it on to next route if needed. Used when forcing Pnum. */
  public enteredEmail: string = "";
  public submitting: boolean = false;
  public showNoSwedishPnum: boolean = false;
  public memberType :string = "";
  public created(){
    this.memberType = AuthFlowContext.memberType;
    if(AuthFlowContext.memberType === "senior"){
      this.showNoSwedishPnum = false;
    }
  }
  public inputChanged(newValue: any) {
    this.hasError = false;
    this.input = newValue.value;
    this.isFormValid = !newValue.hasError;
  }

  public async continueClick() {
    AuthFlowContext.reset();
    ValidateAll();
    if(!this.isFormValid) return;
    this.submitting = true;
    // Kolla om användare inputa epost.
    if (EmailValidator.validate(this.input)) {
      await this.handleEmailInput(this.input);
    } else {
      const tencharpnum = PnumSanitizer.TenCharPnum(this.input);
      await this.handlePnumInput(tencharpnum);
    }
    this.submitting = false;
  }

  public async handleEmailInput(email: string) {
    this.enteredEmail = email;
    const response = await UserService.GetEmailStatus(email, AuthFlowContext.memberType);

    if (response.memberStatus === "invalid") {
      // Invalid email
      this.hasError = true;
      this.errorMessage = this.$t("mec_input:error_invalid_format").toString();
      return;
    }
    const acceptTermsText = (this.$refs.policyel as HTMLElement).innerText;
    if(response.memberStatus !== "senior"){
      UserService.AcceptTerms(acceptTermsText, "", email);
    }
    if (((response.memberStatus === "studentoralumni")|| response.memberStatus === "senior") && response.availableScopes.indexOf("email") !== -1) {
      // Student has verified with us before, send him to enter passwordview
      this.$router.push({ name: "PasswordLogin", params: { email } });
      return;
    } else if (response.memberStatus === "studentoralumni" && response.availableScopes.indexOf("email") === -1) {
      // This email is an Email2 belonging to a student that does not have a primary email set.
      // We send this person to lost password view so he/she can reset password directly.
      this.$router.push({ name: Routes.LoginCodeSentName, params: { email } });
      return;

    }
    else if (response.memberStatus === "unknown" || response.memberStatus === "other") {
      this.forcePnum = true;
      this.email = email;
      return;

    } else throw new Error(`Unhandled response ${response.memberStatus} ${email}`);
  }

  public async handlePnumInput(pnum: string) {
    const response = await UserService.GetPnumStatus(pnum, AuthFlowContext.memberType);
    let email = this.email;
    if (response.memberStatus === "invalid") {
      // Invalid pnum
      this.hasError = true;
      this.errorMessage = this.$t("mec_input:error_invalid_format").toString();
      return;
    }
    const acceptTermsText = (this.$refs.policyel as HTMLElement).innerText;
    UserService.AcceptTerms(acceptTermsText, pnum, "");
    if ((response.memberStatus === "studentoralumni" || response.memberStatus === "senior") && response.availableScopes.indexOf("email") !== -1) {
      // member has verified with us before, send him to enter passwordview
      this.$router.push({ name: "PasswordLogin", params: { pnum } });
      return;

    } else if (response.memberStatus === "studentoralumni" ) {
      // Student has probably never verified with us before, send him to authenticationview.
      this.$router.push({ name: "PnumAuthentication", params: { pnum, memberStatus: response.memberStatus } });
      return;

    } else if (response.memberStatus === "unknown" || response.memberStatus === "other") {
      const existing = response.memberStatus === "other";
      if (AuthFlowContext.memberType === "alumni") {
        this.$router.push({name: Routes.BecomeAlumniBankIdName, params: { pnum, acceptTermsText}});
        return;
      }
      if (AuthFlowContext.memberType === "senior") {
        var valid = SeniorValidator.validate(this.input);
        if(!valid){
          this.hasError = true;
          this.errorMessage = this.$t("start_sweden:fiftyfiveplus").toString();
          return;
        }
        this.$router.push({name: Routes.SeniorRegistrationName, params: { pnum,email, acceptTermsText}});
        return;
      }
      this.$router.push({ name: "StudentApply", params: { pnum, email: this.enteredEmail, existing: existing as any } });
      return;

    } else if (response.memberStatus === "earlydispatch" && AuthFlowContext.memberType === "student") {
      // Early dispatch verification
      this.$router.push({ name: Routes.EarlyDispatchConfirmName, params: { pnum, acceptTermsText } });

    } else throw new Error(`Unhandled response ${response.memberStatus} ${pnum}`);
  }
  public get shouldAutofocus(){
    const isIos = navigator.userAgent.match(/(iPhone|iPod|iPad)/i);
    return !isIos;
  }

  get isSenior(){
			return this.memberType == "senior";
		}

		get isAlumni(){
			return this.memberType == "alumni";
		}
}
