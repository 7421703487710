
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import { WindowService } from "@/services/window/window-service";
import ActionButton from "@/components/forms/ActionButton.vue";
import { UrlHelper } from '@/utilities/UrlHelper';
@Component({
  components: {
    InfoBlock,
    MecenatLogo,
    ActionButton,
  },
})
export default class AlumniTryingToAccessStudent extends Vue {
    @Prop(String) public continueLink!: string;
    private submitting: boolean = false;

    public continueClick() {
      this.submitting = true;
      let newRedirectLink = "https://mecenatalumni.com/se?" + "mecenat_id=" + AuthenticatedUser.rawJwt;
      

      if(AuthFlowContext.redirectUrl.toLowerCase().indexOf("/hydra/") !== -1){
        newRedirectLink = UrlHelper.addParamToUrl("mecenat_id", AuthenticatedUser.rawJwt as string, decodeURIComponent(AuthFlowContext.redirectUrl));
      }

      if (AuthFlowContext.popup) {
        try {
              WindowService.PostMessageToOpener({ messageType: "ConnectRedirect", redirect: newRedirectLink }, AuthFlowContext.redirectUrl);
              window.close();
          } catch (err:any) {
              window.location.href = newRedirectLink;
          }
      } else {
        window.location.href = newRedirectLink;

      }
    }

}
