
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({

})
export default class AppContent extends Vue {
  @Prop({type: Boolean, default: true})
  private useTransition!: boolean;
}
