
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {

  },
})
export default class SocialLogin extends Vue {
    public googleAuthURL: string = "#"; // google btn should link to this.
    public fbAuthURL: string = "#"; // fb btn should link to this
}
