import axios, { AxiosStatic } from "axios";
export class AuthService {

  constructor(private baseURL: string, private requestVerificationToken: string, private axiosInstance: AxiosStatic) {

  }


  public async Authenticate(memberType:string, password: string, pnum?: string | null, email?: string | null): Promise<AuthenticateStatusResponse> {
    const endpoint = this.baseURL + "/authenticate";
    const pnumOrEmail = pnum ? "personal number" : "email";
    gtag("event", "login submit", {event_category: "login", event_label: pnumOrEmail + " and password" });
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { personalnumber: pnum, password, email, memberType }, requestConfig);
      gtag("event", "login " + response.data.authStatus, {event_category: "login", event_label: pnumOrEmail + " and password" });
      return response.data;
    } catch (err:any) {
      if (err.response.status < 500) {
        gtag("event", "login error", {event_category: "Login", event_label: pnumOrEmail + " and password" });
        return err.response.data;
      }

      return new Promise(() => ({ code: 500, message: err }));
    }
  }

  public async AuthenticateWithStudentId(pnum: string, studentid: string): Promise<AuthenticateStatusResponse> {
    const endpoint = this.baseURL + "/authenticate/studentid";
    gtag("event", "verification submit", {event_category: "verification", event_label: "studentid" });
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { personalnumber: pnum, studentid }, requestConfig);
      const data: AuthenticateStatusResponse = response.data;
      gtag("event", "verification " + data.authStatus, {event_category: "verification", event_label: "studentid" });

      return data;
    } catch (err:any) {
      if (err.response.status < 500) {
        gtag("event", "verification error", {event_category: "verification", event_label: "studentid" });
        return err.response.data;
      }

      return new Promise(() => ({ code: 500, message: err }));
    }
  }

  public async AuthenticateWithEarlyDispatch(pnum: string, confirmed:boolean, acceptTermsText:string): Promise<EarlyDispatchStatusResponse> {
    const endpoint = this.baseURL + "/authenticate/earlydispatch";
    gtag("event", "verification submit", {event_category: "verification", event_label: "earlydispatch" });
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { personalnumber: pnum, confirmed, acceptTermsText }, requestConfig);
      const data: EarlyDispatchStatusResponse = response.data;
      gtag("event", "verification " + data.authStatus, {event_category: "verification", event_label: "earlydispatch" });

      return data;
    } catch (err:any) {
      if (err.response.status < 500) {
        gtag("event", "verification error", {event_category: "verification", event_label: "earlydispatch" });
        return err.response.data;
      }

      return new Promise(() => ({ code: 500, message: err }));
    }
  }

}

export interface AuthenticateStatusResponse {
  authStatus: AuthStatus;
  code: number; // 200: allGood, 403: wrong password, 400:Invalid format
  message: string;
  authToken: string;
}
export interface EarlyDispatchStatusResponse {
  authStatus: AuthStatus;
  code: number; // 200: allGood, 403: wrong password, 400:Invalid format
  message: string;
  authToken: string;
  firstnameMasked: string;
  lastnameMasked: string;
}
export type AuthStatus =
  "success" | // successfull auth
  "failed" | // wrong credentials
  "invalid"; // Invalid request
