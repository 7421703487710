
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import ActionButton from "@/components/forms/ActionButton.vue";

import {EmailValidator} from "@/validation/EmailValidator";
import {PasswordValidator} from "@/validation/PasswordValidator";
import {MobilePhoneValidator} from "@/validation/MobilePhoneValidator";
import { Authorize } from "../authorize";
import { FacebookPixel } from "../utilities/FacebookPixel";

@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,
  },
})
export default class AccountInfoNeeded extends Vue {
  @Prop(String) public email?: string;
  public showPasswordTip = false;
  private inputEmail: string = "";
  private memberType: string ="";
  private emailValidate = EmailValidator.validate;
  private passwordValidate = PasswordValidator.validate;
  private mobilePhoneValidate = MobilePhoneValidator.validate;
  private inputPassword: string = "";
  private inputPhoneMobile: string ="";
  private submitting: boolean = false;
  private passwordVisible: boolean = false;
  public created() {
    FacebookPixel.fbPixelInit();
    this.memberType = AuthFlowContext.memberType;
    if (this.email) {
      this.inputEmail = this.email;
    }
  }
  public async continueClick() {
    if (!this.isFormValid) return;
    this.submitting = true;
    const resp = await UserService.ActivateProfile(AuthenticatedUser.uid as string, this.inputEmail, this.inputPassword, AuthFlowContext.referringSource as string, this.inputPhoneMobile, AuthFlowContext.referringCode as string);
    if (resp.code < 299) {
      FacebookPixel.fbPixelUserActivated();
      if (this.showPolicy) {
        const acceptTermsText = (this.$refs.policyel as HTMLElement).innerText;
        await UserService.AcceptTerms(acceptTermsText, "", "", AuthenticatedUser.uid);
      }
      const authResp = await Authorize.redirectFlow(AuthFlowContext, resp.authToken);
      this.$router.push({ name: authResp.internalRedirect as string, params: authResp.internalRedirectProps });
      return;
    } else throw new Error(`Unhandled response ${resp.code} : ${resp.message} ${AuthenticatedUser.uid} ${this.inputEmail}`);
    this.submitting = false;
  }
  public togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  public focusPassword() {
    const element = document.getElementById("password") as HTMLElement;
    element.focus();
  }
  public isFormValid() {
    if (this.emailValidate(this.inputEmail) && this.passwordValidate(this.inputPassword) && (this.inputPhoneMobile === "" || this.mobilePhoneValidate(this.inputPhoneMobile))) {
      return true;
    }
    return false;
  }
  /** We only need to show policy if we authed the user automatically from url parameter. */
  public get showPolicy() {
    if (AuthFlowContext.autoAuthCode) { return true; }

    return false;
  }
}
