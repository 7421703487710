import { UserContext } from "@/context/UserContext";

/* tslint:disable */
export class FacebookPixel {
    public static fbPixelInit() {
        if(AuthFlowContext.isApp)
            return;
        try {
            const fbFunction = (f: Window, b: Document, e: string, v: string, n?: any, t?: any, s?: any) => {
                if (f.fbq)
                    return;
                n = f.fbq = function () {
                    n.callMethod
                        ? n.callMethod.apply(n, arguments)
                        : n.queue.push(arguments)
                };
                if (!f._fbq)
                    f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            };
            fbFunction(window, document, 'script', '//connect.facebook.net/en_US/fbevents.js');

            fbq('init', '1623477004568316');

        } catch (error) {
            console.warn("Fb pixel error", error)
        }
    }
    public static fbPixelUserActivated() {
        if(AuthFlowContext.isApp)
            return;
        try{
            fbq('track', 'CompleteRegistration', {
                value: 1.00,
                currency: 'SEK'
                });
        }catch(error){
            console.warn("fb pixel useractivated event error");
        }
    }

}