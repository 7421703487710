
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import { WindowService } from "@/services/window/window-service";
import ActionButton from "@/components/forms/ActionButton.vue";
@Component({
  components: {
    InfoBlock,
    MecenatLogo,
    ActionButton,
  },
})
export default class StudentTryingToAccessAlumni extends Vue {
    @Prop(String) public continueLink!: string;
    public continueClick() {
      const newRedirectLink = "https://mecenat.com/se?" + "mecenat_id=" + AuthenticatedUser.rawJwt;
      if (AuthFlowContext.popup) {
        try {
              WindowService.PostMessageToOpener({ messageType: "ConnectRedirect", redirect: newRedirectLink }, AuthFlowContext.redirectUrl);
              window.close();
          } catch (err:any) {
              window.location.href = newRedirectLink;
          }
      } else {
        window.location.href = newRedirectLink;

      }
    }
}
