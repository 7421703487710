
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
@Component({
  components: {
    InfoBlock,
    MecenatLogo,
  },
})
export default class NoSwedishPnum extends Vue {

}
