import axios, { AxiosStatic, AxiosRequestConfig } from "axios";
import { MemberStatus, UserContext } from "@/context/UserContext";
import { AuthFlowContext } from "@/context/AuthFlowContext";
import { UrlHelper } from "@/utilities/UrlHelper";
export class UserService {

  constructor(private baseURL: string, private requestVerificationToken: string, private axiosInstance: AxiosStatic) {

  }

  public async GetPnumStatus(pnum: string, requestedMemberType: string): Promise<GetMemberStatusResponse> {
    const endpoint = this.baseURL + "/member/pnumstatus";

    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { personalnumber: pnum, requestedmembertype: requestedMemberType }, requestConfig);
      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, memberStatus: "unknown", availableScopes: [] };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, memberStatus: "unknown", availableScopes: [] };
    }
  }

  public async GetEmailStatus(email: string, requestedMemberType: string): Promise<GetMemberStatusResponse> {
    const endpoint = this.baseURL + "/member/emailstatus";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      const response = await this.axiosInstance.post(endpoint, { email, requestedmembertype: requestedMemberType }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, memberStatus: "unknown", availableScopes: [] };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, memberStatus: "unknown", availableScopes: [] };
    }
  }

  public async ResetPasswordRequest(callbackUrl: string, lang: string, authflow: AuthFlowContext, pnum?: string, email?: string, secondaryEmail?: string, sendResetToSecondaryEmail?: boolean): Promise<ResetPasswordResponse> {
    const endpoint = this.baseURL + "/member/resetpassword";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
          "lang": lang,
        },
        withCredentials: true,
      };
      callbackUrl = UrlHelper.addParamToUrl("clientId", authflow.clientId, callbackUrl);
      let memberType = authflow.memberType;
      // Since web doesnt support hydraflow yet we dont send the hydra redirect uri in the link.
      if(!authflow.isHydraFlow && (authflow.redirectUrl.indexOf("cardsvc") === -1)) callbackUrl = UrlHelper.addParamToUrl("redirect_uri", encodeURIComponent(authflow.redirectUrl), callbackUrl);
      
      callbackUrl = UrlHelper.addParamToUrl("state", authflow.state, callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("scope", authflow.scope.join(" "), callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("member_type", authflow.memberType, callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("forceMemberType", String(authflow.forceMemberType), callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("lang", authflow.lang, callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("popup", "false", callbackUrl);
      const response = await this.axiosInstance.post(endpoint, {callbackUrl, personalnumber: pnum, email, secondaryEmail, sendtosecondary: sendResetToSecondaryEmail, memberType}, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, memberStatus: "unknown" };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, memberStatus: "unknown" };
    }
  }
  public async ChangePassword(uid: string, newPassword: string, authflow:AuthFlowContext): Promise<ChangePasswordResponse> {
    const endpoint = this.baseURL + "/member/changepassword";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      let memberType = authflow.memberType;
      const response = await this.axiosInstance.post(endpoint, {newPassword,memberType, userId: uid }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }
  public async ValidateResetPasswordCode(resetCode: string,authflow:AuthFlowContext, pnum?: string, email?: string, sendToSecondaryEmail?: boolean): Promise<ValidateResetPasswordCodeResponse> {
    const endpoint = this.baseURL + "/member/validateresetpasswordcode";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      let memberType = authflow.memberType;
      const response = await this.axiosInstance.post(endpoint, {resetCode, personalnumber: pnum, email,memberType, sendtosecondary: sendToSecondaryEmail }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, authToken: "" };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, authToken: "" };
    }
  }
  public async RequestLoginCode(callbackUrl: string, lang: string, email: string, authflow: AuthFlowContext): Promise<DefaultResponse> {
    const endpoint = this.baseURL + "/member/requestlogincode";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
          "lang": lang,
        },
        withCredentials: true,
      };
      callbackUrl = UrlHelper.addParamToUrl("clientId", authflow.clientId, callbackUrl);
      2
      // If its not old hydraflow and not cardsvc in redirecturi we add the redirect uri to the link.
      if(!authflow.isHydraFlow && (authflow.redirectUrl.indexOf("cardsvc") === -1)) 
        callbackUrl = UrlHelper.addParamToUrl("redirect_uri", encodeURIComponent(authflow.redirectUrl), callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("state", authflow.state, callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("scope", authflow.scope.join(" "), callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("memberType", authflow.memberType, callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("forceMemberType", String(authflow.forceMemberType), callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("lang", authflow.lang, callbackUrl);
      callbackUrl = UrlHelper.addParamToUrl("popup", "false", callbackUrl);
      let memberType = authflow.memberType;

      const response = await this.axiosInstance.post(endpoint, { email, callbackUrl,memberType }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }
  public async ValidateLoginCode(loginCode: string,authflow:AuthFlowContext, email?: string): Promise<ValidateLoginCodeResponse> {
    const endpoint = this.baseURL + "/member/validatelogincode";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      let memberType = authflow.memberType;
      const response = await this.axiosInstance.post(endpoint, {loginCode,memberType, email }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, authToken: "" };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, authToken: "" };
    }
  }
  public async GetMaskedEmail(pnum: string, memberType:string): Promise<GetMaskedEmailResponse> {
    const endpoint = this.baseURL + "/member/getmaskedemail";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      const response = await this.axiosInstance.post(endpoint, { pnum, memberType }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }
  public async GetMaskedName(pnum: string): Promise<GetMaskedNameResponse> {
    const endpoint = this.baseURL + "/member/getmaskedname";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      const response = await this.axiosInstance.post(endpoint, { pnum }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }
  public async AcceptTerms(termsAcceptText: string, pnum?: string, email?: string, uid?: string) {
    const endpoint = this.baseURL + "/member/termsaccept";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      const response = await this.axiosInstance.post(endpoint, { personalnumber: pnum, email, termsAcceptText, userid: uid }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }

  // ** Activates the users profile. Sets email, pw, activated, enables sms, pushes and emailnews */
  public async ActivateProfile(uid: string, email: string, pw: string, referringSource: string, phoneMobile: string, referringCode: string): Promise<ActivateResponse> {
    const endpoint = this.baseURL + "/member/activate";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      const response = await this.axiosInstance.post(endpoint, { UserId: uid, Password: pw, Email: email, ReferringSource: referringSource, PhoneMobile:phoneMobile, ReferringCode: referringCode}, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, authToken: "" };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, authToken: "" };
    }
  }
    // ** Creates a new senior user */
    public async RegisterSenior(pnum:string, email: string, pw: string, referringSource: string, phoneMobile: string, referringCode: string): Promise<ActivateResponse> {
      const endpoint = this.baseURL + "/member/registersenior";
      try {
        const token = this.requestVerificationToken;
        const requestConfig = {
          headers: {
            "Content-Type": "application/json",
            "RequestVerificationToken": token,
            "X-Mec-Office": GlobalEnvironment.isOffice ? "1" : undefined
          },
          withCredentials: true,
        };
        const response = await this.axiosInstance.post(endpoint, { ssn: pnum, Password: pw, Email: email, ReferringSource: referringSource, Phone:phoneMobile, ReferringCode: referringCode}, requestConfig);
  
        return response.data;
      } catch (err:any) {
        if (!err.response) return { code: 500, message: err, authToken: "" };
  
        if (err.response.status < 500) {
          return err.response.data;
        }
  
        return { code: 500, message: err, authToken: "" };
      }
    }
    
  public async GetConsents(uid: string): Promise<ConsentResponse> {
    const endpoint = this.baseURL + "/member/consents/" + uid;
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
      };
      const response = await this.axiosInstance.get(endpoint, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, acceptedConsents: [], deniedConsents: [], answeredConsents: [] };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, acceptedConsents: [], deniedConsents: [], answeredConsents: []  };
    }
  }
  public async GetSchoolInfo(uid: string): Promise<SchoolInfoResponse> {
    const endpoint = this.baseURL + "/member/schoolinfo/" + uid;
    
    try {
      const token = this.requestVerificationToken;
      const requestConfig: AxiosRequestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
        timeout: 6000,
      };
      const response = await this.axiosInstance.get(endpoint, requestConfig);

      return response.data;
    } catch (err:any) {

      // If timeout then return null. Probably old DB2 that is slow.
      if (err.code === "ECONNABORTED") {
        console.error("Timeout getting school info");
        return { code: 408, message: "timeout" };
      }

      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }

  // ** Activates the users profile. Sets email, pw, activated, enables sms, pushes and emailnews */
  public async UpdateSchoolInfo(uid: string, graduationYear: number, fieldOfStudyId: number): Promise<DefaultResponse> {
    const endpoint = this.baseURL + "/member/schoolinfo";
    try {
      const token = this.requestVerificationToken;
      const requestConfig: AxiosRequestConfig = {
        headers: {
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
        withCredentials: true,
        timeout: 5000,
      };
      const response = await this.axiosInstance.post(endpoint, { UserId: uid, GraduationYear: graduationYear, FieldOfStudyId: fieldOfStudyId}, requestConfig);

      return response.data;
    } catch (err:any) {

      // If timeout then return null. Probably old DB2 that is slow.
      if (err.code === "ECONNABORTED") {
        console.error("Timeout updating school info");
        return { code: 408, message: "timeout" };
      }
      if (!err.response) return { code: 500, message: err };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err };
    }
  }

  public async AlumniApplicationBankIdInitialize(pnum?: string): Promise<AlumniApplicationBankidInitializeResponse> {
    const endpoint = this.baseURL + "/member/alumniapplyv2";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { ssn: pnum }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, bankIdReference: "", autoStartToken: "", isIos: false, qrSS: "", qrStartToken: ""};

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, bankIdReference: "", autoStartToken: "", isIos: false, qrSS: "", qrStartToken: ""};
    }
  }
  public async AlumniApplicationBankIdPoll(bankIdReference: string, pnum?: string, acceptTermsText?:string): Promise<AlumniApplicationBankidPollResponse> {
    const endpoint = this.baseURL + "/member/alumniapplyv2/poll";
    try {
      const token = this.requestVerificationToken;
      const requestConfig = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "RequestVerificationToken": token,
        },
      };
      const response = await this.axiosInstance.post(endpoint, { bankIdReference, ssn: pnum, acceptTermsText }, requestConfig);

      return response.data;
    } catch (err:any) {
      if (!err.response) return { code: 500, message: err, authToken: "", completed: false };

      if (err.response.status < 500) {
        return err.response.data;
      }

      return { code: 500, message: err, authToken: "", completed: false };
    }
  }
}

export interface GetMemberStatusResponse {
  memberStatus: MemberStatus;
  availableScopes: string[]; // Information we can provide about the member, f.ex Profile, email e.t.c.
  code: number; // 200: allGood, 429: Too many requests from this token, 400:Invalid format
  message: string;
}

export interface GetMaskedEmailResponse {
  primaryEmail?: string;
  secondaryEmail?: string;
  code: number;
  message: string;
}
export interface GetMaskedNameResponse {
  maskedFname?: string;
  maskedLname?: string;
  code: number;
  message: string;
}
export interface ResetPasswordResponse {
  code: number;
  message: string;
  memberStatus: MemberStatus;
}
export interface ValidateResetPasswordCodeResponse {
  code: number;
  message: string;
  authToken: string;
}

export interface ValidateLoginCodeResponse {
  code: number;
  message: string;
  authToken: string;
}
export interface ChangePasswordResponse {
  code: number;
  message: string;
}

export interface ActivateResponse {
  code: number;
  message: string;
  authToken: string;
}
export interface ConsentResponse {
  code: number;
  message: string;
  acceptedConsents: string[];
  deniedConsents: string[];
  answeredConsents: string[];
}
export interface SchoolInfoResponse {
  code: number;
  message: string;
  isFieldOfStudyOutOfDate?: boolean;
  graduationYear?: number;
  fieldOfStudyId?: number;
  schoolId?: number;
}
interface AlumniApplicationBankidInitializeResponse {
  bankIdReference: string;
  autoStartToken: string;
  qrStartToken: string;
  qrSS: string;
  isIos: boolean;
  code: number;
  message: string;
}

interface AlumniApplicationBankidPollResponse {
    authToken: string;
    completed: boolean;
    code: number;
    message: string;
  }

export interface DefaultResponse {
  code: number;
  message: string;
}
