import {DirectiveOptions} from "vue";

const directive: DirectiveOptions = {
    async bind(el: HTMLElement, binding, vnode) {
      const url = `${GlobalEnvironment.assetBasePath}${binding.value}`;
      const attribute = binding.arg;
      if (attribute) {
        el.setAttribute(attribute, url);
      }
    },
    async update(el: HTMLElement, binding, vnode) {
      const url = `${GlobalEnvironment.assetBasePath}${binding.value}`;
      const attribute = binding.arg;
      if (attribute) {
        el.setAttribute(attribute, url);
      }
    },
  };
export default directive;
