
import {Component, Vue, Prop} from "vue-property-decorator";
import MecInput from "@/components/forms/MecInput.vue";
import MecenatLogo from "@/components/MecenatLogo.vue";
import ActionButton from "@/components/forms/ActionButton.vue";
import InfoBlock from "@/components/InfoBlock.vue";
import Axios from "axios";
import {EmailValidator} from "@/validation/EmailValidator";
import {PnumSanitizer} from "@/utilities/PnumSanitizer";

@Component({
  components: {
      MecInput,
      MecenatLogo,
      ActionButton,
      InfoBlock,
  },
})
export default class BecomeAlumni extends Vue {
    @Prop(String) public pnum?: string;
    @Prop(String) public email?: string;
    @Prop(Boolean) public existing?: boolean;
    private emailValidator = EmailValidator.validate;
    private inputFname: string = "";
    private inputLname: string = "";
    private inputStreet?: string = "";
    private inputCity?: string = "";
    private inputPostalCode?: string = "";
    private inputEmail?: string = "";
    private inputMessage?: string = "";
    private applicationSent: boolean = false;
    private inputFileUpload: any = "";
    private fileHasError: boolean = false;
    private fileErrorMsg: string = "";

    private sendingApplication: boolean = false;

    public created() {
        if (this.email) {
            this.inputEmail = this.email;
        }
    }

    public continueClick() {
        const formdata = new FormData();
        let ssn = this.pnum as string;
        ssn = PnumSanitizer.TenCharPnum(ssn);
        formdata.append("files", this.inputFileUpload as string);
        formdata.append("firstname", this.inputFname as string);
        formdata.append("lastname", this.inputLname as string);
        formdata.append("street", this.inputStreet as string);
        formdata.append("postalcode", this.inputPostalCode as string);
        formdata.append("city", this.inputCity as string);
        formdata.append("email", this.inputEmail as string);
        formdata.append("personalnumber", ssn as string);

        formdata.append("message", this.inputMessage as string);
        formdata.append("userlang", this.$i18n.locale);

        const config = {
            headers: {"content-type": "multipart/form-data"},
        };
        this.sendingApplication = true;
        const uploadService = Axios.post("/2/api/card/alumniapply", formdata, config)
        .then((result) => {
            if (result.status === 200) {
                this.applicationSent = true;
                this.sendingApplication = false;
            }
        }, (err) => {
            this.sendingApplication = false;
        });
    }
    public filesChange(a: any, b: any) {
        this.fileHasError = false;
        this.fileErrorMsg = "";
        const filesElem = document.getElementById("fileupload") as HTMLInputElement;
        const files = filesElem.files;
        if (!files) { return; }
        if (files.length < 1) { return; }
        const file = files[0];

        // dont forget to change on serverside
        const approvedExtensions = ["pdf", "odt", "ods", "jpg", "png", "bmp", "jpeg", "doc", "docx", "psd", "tif", "zip", "webp", "heic"];
        const fileExtension = file.name.split(".").pop();
        if (approvedExtensions.indexOf(fileExtension as string) < 0) {
            this.fileHasError = true;
            this.fileErrorMsg = this.$t("become_alumni:wrong_fileextension", this.$i18n.locale).toString();
            return;
        }
        const maxByteSize = 10000000; // 10mb

        if (file.size > maxByteSize) {
            this.fileHasError = true;
            this.fileErrorMsg = this.$t("become_alumni:file_too_big", this.$i18n.locale).toString();
            return;
        }

        this.inputFileUpload = file;
    }
    private get formValid() {
        if (this.pnum && this.inputFname && this.inputLname && this.inputStreet && this.inputCity && this.inputPostalCode && this.inputEmail && this.inputFileUpload) {
            return true;
        }
        return false;
    }
}
