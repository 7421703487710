
import { Component, Vue, Prop } from "vue-property-decorator";
import TermsAndConditionEn from "./TermsAndCondition.en.html";
import TermsAndConditionSv from "./TermsAndCondition.sv.html";
import { Authorize } from "@/authorize";
import i18n from "../i18n";
import { WindowService } from "@/services/window/window-service";
const TermsSe = Vue.extend({ template: TermsAndConditionSv });
const TermsEn = Vue.extend({ template: TermsAndConditionEn });

export default Vue.extend({
  data() {
    return {};
  },
  mounted() {
    console.log("mounting terms");
  },
  computed: {
    isSv() {
      return i18n.locale === "sv";
    },
    termsAcceptText() {
      return (
        i18n.t("continue_accept_terms", i18n.locale) +
        " " +
        i18n.t("policy", i18n.locale).toString().toLowerCase()
      );
    },
  },
  methods: {
    hasUid() {
      return this.$route.query.uid != null || AuthenticatedUser.uid;
    },
    async continueClick() {
      const uid = this.$route.query.uid || AuthenticatedUser.uid;
      await UserService.AcceptTerms(
        this.termsAcceptText,
        "",
        "",
        uid as string
      );
      const newRedirectLink = AuthFlowContext.redirectUrl;
      const authResp = await Authorize.redirectFlow(
        AuthFlowContext,
        AuthenticatedUser.rawJwt as string
      );
      this.$router.push({
        name: authResp.internalRedirect as string,
        params: authResp.internalRedirectProps,
      });
    },
  },
  components: {
    TermsSe,
    TermsEn,
  },
});
