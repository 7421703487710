
export class Hotjar {
    public static SetContext() {
        //if (AuthFlowContext.isApp) hj("tagRecording", ["app"]);
        //else hj("tagRecording", ["web"]);

        //hj("tagRecording", [AuthFlowContext.memberType]);
        //hj("tagRecording", [AuthFlowContext.flow]);
    }

}
