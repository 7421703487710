export class PasswordValidator {

    public static validate(value: string): boolean {
        if (!value) { return false; }

        // Length
        if (value.length < PasswordValidator.minLength) { console.log("length invalid"); return false; }

        // mixedCases
        if (!/^(?=.*[a-z])(.*?[A-Z]){1,}.*$/.test(value)) { console.log("case invalid"); return false; }

        // Numeric char
        if (!/^(?=.*\d).+$/.test(value)) { console.log("numeric invalid"); return false; }

        return true;
    }
    private static minLength: number = 8;

}
