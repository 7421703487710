import { UrlHelper } from "@/utilities/UrlHelper";
import * as Cookie from "js-cookie";
export class GoogleAnalytics {
    public static UAKey = "UA-2423271-23";
    public static GA4Code = "G-ZQ5NZFTPGG";
    public static SetContext() {
        const lang = Cookie.get("lang");
        gtag('config', GoogleAnalytics.GA4Code, {
            'linker': {
                'domains': ['connect.mecenat.com', 'mecenatalumni.com', 'mecenat.com', 'seniordays.com']
            },
            'anonymize_ip': true,
            'transport_type': 'beacon',
            'send_page_view': false,
            'groups': 'analytics'

        });
    }
    public static TrackCurrentPageView(pageName: string, pagePath: string) {
        gtag('event', 'page_view', {
            page_title: pageName,
            page_location: window.location.origin + pagePath.replace(/\/$/, ""),
            page_path: pagePath.replace(/\/$/, ""), 
            send_to: "analytics",
            dimension1: AuthFlowContext.popup,
            dimension2: AuthFlowContext.flow,
            is_app: AuthFlowContext.isApp ? `app` : undefined,
        })
    }
    private static getUnpersonifiedPageUrl(includeUrlHost: boolean) {
        let url;
        if (includeUrlHost) {
          url = window.location.href.toLowerCase();
        } else {
          url =
            window.location.pathname.toLowerCase() +
            window.location.search.toLowerCase();
        }
        url = UrlHelper.removeParamFromUrl("uid", url); // Remove Uid parameter
        url = UrlHelper.removeParamFromUrl("mecenat_id", url); // Remove discountcode parameter
        url = UrlHelper.removeParamFromUrl("auto", url);
        url = UrlHelper.removeParamFromUrl("id", url);
        return url;
      }
}
