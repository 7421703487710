

import Vue from "vue";

// We only want error logged maximum x times per request, incase there is a loop.
const maxLogCount = 1;
const errorEndpoint = "/error/newjs";
let errorCounter = 0;

interface ErrorObject {
  message: string | Event;
  source?: string;
  lineno?: number;
  colno?: number;
  stack: string;
  url: string;
  environment: string;
}
export function JsError(message: any, source: any, lineno: any, colno: any, error: any): void {
  // just one error per time please
  if (errorCounter >= maxLogCount) {
    return;
  }
  errorCounter++;

  let stack: any = "";
  if (error) {stack = error; }

  const errorObj: ErrorObject = { message, source, lineno, colno, stack, url: window.location.href, environment: "Connect Client"};

  // issue with i18n throwing these on some pagechanges, doesnt affect user, remove this entire block and test once https://github.com/kazupon/vue-i18n/issues/184 is fixed
  if ((errorObj.message.toString().indexOf("TypeError: null is not an object (evaluating 'r._t')") !== -1) || (errorObj.message.toString().indexOf("TypeError: Cannot read property '_t' of null") !== -1)){
    return;
  }
  console.log("js error", errorObj);
  if (errorObj.url.indexOf("http://localhost") !== -1) {
    console.error(errorObj);
    return;
  }
  reportErr(errorObj);
}
export function VueError(err: Error, vm: Vue, info: string) {
  // just one error per time please
  if (errorCounter >= maxLogCount) {
    return;
  }

  errorCounter++;
  const errorObj: ErrorObject = {
    message: err.message,
    source: `vue: ${info} in ${vm.$vnode.tag}`,
    url: window.location.href,
    stack: err.stack ? err.stack : "",
    environment: "Connect Client",
  };

  // issue with i18n throwing these on some pagechanges, doesnt affect user, remove this entire block and test once https://github.com/kazupon/vue-i18n/issues/184 is fixed
  if ((errorObj.message.toString().indexOf("TypeError: null is not an object (evaluating 'r._t')") !== -1) || (errorObj.message.toString().indexOf("TypeError: Cannot read property '_t' of null") !== -1)){
    return;
  }

  if (errorObj.url.indexOf("http://localhost") !== -1) {
    console.error(err);
    console.error("vue-obj:", vm);
    return;
  }

  reportErr(errorObj);
}

function reportErr(data: ErrorObject) {
  const XHR = new XMLHttpRequest();
  let urlEncodedData = "";
  const urlEncodedDataPairs = [];

  // Turn the data object into an array of URL-encoded key/value pairs.
  for (const [key, value] of Object.entries(data)) {
    urlEncodedDataPairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
  }

  // Combine the pairs into a single string and replace all %-encoded spaces to
  // the '+' character; matches the behaviour of browser form submissions.
  urlEncodedData = urlEncodedDataPairs.join("&").replace(/%20/g, "+");

  console.log("urlEncodedData", urlEncodedData);

  // Set up our request
  XHR.open("POST", errorEndpoint);

  // Add the required HTTP header for form data POST requests
  XHR.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  // Finally, send our data.
  XHR.send(urlEncodedData);
}


