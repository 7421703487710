
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import { Routes } from "../router";
import { UserContext } from "../context/UserContext";
import { Authorize } from "../authorize";
import ActionButton from "@/components/forms/ActionButton.vue";
import {PnumSanitizer} from "@/utilities/PnumSanitizer";
import qrcode from 'qrcode'

@Component({
  components: {
    ActionButton,
    InfoBlock,
  },
})
export default class BankId extends Vue {
  @Prop()
  public pnum!: string;
  @Prop()
  public acceptTermsText!: string;
  public trackingName: string ="AlumniRegistration";
  private startBankIdBtnEnabled: boolean = true;
  
  public inputAlumniConfirm = true;
  private startBankIdLink?: string;
  private autostartToken: string = "";
  private qrSS: string = "";
  private qrStartToken: string ="";
  private qrTime: number = -1;
  private qrTimeInterval: any;
  private bankIdQrCode: string = "";
  private maxPollAmount: number = 20;
  private pollCount: number = 0;
  private status: string = "";
  private startBtnText: string = "";
  private pnumNotNumericMsg = "";
  public async mounted() {
    //this.startBankId();
    const sanitizedPnum = PnumSanitizer.TenCharPnum(this.pnum);
    const isnum = /^\d+$/.test(sanitizedPnum);
    if (!isnum) {
        // Pnum isnt numeric so we need to reroute user to old become alumni.
      //this.noBankidClick()
      return;
    }
    this.startBtnText = this.$t("start-mobilebankid").toString();
  }
  public async startBankId() {
    this.startBankIdBtnEnabled = false;
    this.pollCount = 0;
    this.qrTime = 0;
    this.status = this.$t("initiating-bankid", this.$i18n.locale).toString();
    const resp = await UserService.AlumniApplicationBankIdInitialize(this.pnum);
    console.log("starting bankId response", resp.code, resp.autoStartToken);
    if (resp.code < 300) {

      this.autostartToken = resp.autoStartToken;
      this.qrStartToken = resp.qrStartToken;
      this.qrSS = resp.qrSS;
      this.status = this.$t("scan-qrcode", this.$i18n.locale).toString();
      this.startPoll(resp.bankIdReference);
      this.qrTimeInterval = setInterval(() => { this.qrTime++; this.pollBankIdQrCode();}, 1000);

      if (this.autoStartBankIdLink) {
        window.location.href = this.autoStartBankIdLink;
      }
    } else {
      this.status = this.$t("couldnt-startbankid", this.$i18n.locale).toString();
      this.startBankIdBtnEnabled = true;
    }
  }
  public async startPoll(bankIdReference: string) {
    if (this.pollCount >= this.maxPollAmount) {
      this.$router.push({name: "StartSweden"});
      return;
    }
    this.pollCount++;
    const resp = await UserService.AlumniApplicationBankIdPoll(bankIdReference, this.pnum, this.acceptTermsText);
    const self = this;
    if (resp.code === 200 && !resp.completed) {
      setTimeout(() => { self.startPoll(bankIdReference); }, 2000);
    }
    if (resp.completed && resp.code < 300) {
      AuthenticatedUser = new UserContext(resp.authToken);
      gtag("event", this.trackingName + " success" , {event_category: this.trackingName, event_label: "bankid" });
      Authorize.setAuthCookie(AuthenticatedUser);
      this.$router.push({name: Routes.AccountInfoNeededName, params: { uid: AuthenticatedUser.uid as string }});
    } else if (resp.code > 400) {
        this.status = this.$t("couldnt-startbankid", this.$i18n.locale).toString();
        this.startBankIdBtnEnabled = true;
    }
  }

  public get autoStartBankIdLink(): string {
    if (!this.autostartToken) {
      return "";
    }
    const isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);

    if (!isMobile) {return ""; }

    const isIosAndMecenatApp = AuthFlowContext.isApp && navigator.userAgent.match(/(iPhone|iPod|iPad)/i);

    if (isIosAndMecenatApp && AuthFlowContext.appMajorVersion != 0) {
      if (AuthFlowContext.appMajorVersion < 4 && AuthFlowContext.appMinorVersion < 1) {
        return "";
      }
      return `bankid:///?autostarttoken=${this.autostartToken}&redirect=mecenat://`;
    }

    const link = `bankid:///?autostarttoken=${this.autostartToken}&redirect=null`;
    return link;
  }
  public noBankidClick() {
    var pnum = this.pnum;
    this.$router.push({name: Routes.BecomeAlumniName, params: { pnum}});
  }
  public async pollBankIdQrCode(){
      if(this.qrTime == 30){
        clearInterval(this.qrTimeInterval);
        this.startBankIdBtnEnabled = true;
        this.status = this.$t("couldnt-startbankid", this.$i18n.locale).toString();
        
      }
			var qrCodeData = BankidService.generateQRCode(this.qrStartToken, this.qrSS, this.qrTime);
      const options = { scale: 4, width: 4 }
      await qrcode.toDataURL(qrCodeData, options, (err, url) => {
        if (err) {
          console.log(err, url)
        }
        this.bankIdQrCode = url;
    })
      //return qrCodeData;
		}
}
