
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import {Authorize} from "../authorize";
import { UrlHelper } from "../utilities/UrlHelper";
import {Routes} from "@/router";
import { PasswordValidator } from "../validation/PasswordValidator";

import ActionButton from "@/components/forms/ActionButton.vue";
@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,
  },
})
export default class ChangePassword extends Vue {

  public passwordVisible: boolean = false;
  public showSuccess: boolean = false;
  public input!: string;
  public hasError: boolean = false;
  public errorMessage: string = "";
  public isFormValid: boolean = false;
  @Prop(String) public uid!: string;
  public validate: any = PasswordValidator.validate;
  public submitting: boolean = false;
  public inputChanged(newValue: any) {
    this.input = newValue.value;
  }

  public errorChanged(hasError: boolean) {
    this.isFormValid = !hasError;
  }
  public togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  public async changePasswordClick() {
    if (!this.isFormValid) return;
    let uidParam = AuthenticatedUser.uid as string;
    if (!AuthenticatedUser) {
        uidParam = this.$route.query.uid as string;
    }
    this.submitting = true;
    const resp = await UserService.ChangePassword(uidParam, this.input, AuthFlowContext);
    if (resp.code === 200) {
      gtag("event", "change password success" , {event_category: "reset password", event_label: "1"});
      this.showSuccess = true;
    } else if (resp.code === 400) {
      // Wrong input.. The field validation will handle this error msg.
    } else throw new Error(`Unhandled response ${resp.code} : ${resp.message} : ${uidParam} : formvalid: ${this.isFormValid}`);
    this.submitting = false;
  }

  public async continueClick() {
      const resp = await Authorize.redirectFlow(AuthFlowContext, AuthenticatedUser.rawJwt as string);
      this.$router.push({ name: resp.internalRedirect as string, params: resp.internalRedirectProps });
  }
}
