export default class StudentIdValidator {

    public static validate(value: string): boolean {
        if (!value) { return false; }

        // Numeric char
        if (!/^\d+$/.test(value)) { console.log("numeric invalid"); return false; }

        // We trim the first 0 cause some enter their studentid without the 0 and some with.
        const trimmedValue = value.charAt(0) === "0" ? value.substring(1) : value;

        if (trimmedValue.length < StudentIdValidator.minLength) { return false; }

        return true;
    }
    private static minLength: number = 7;

}
