
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import {Authorize} from "../authorize";
import { UrlHelper } from "../utilities/UrlHelper";
import {Routes} from "@/router";
import { UserContext } from "../context/UserContext";
import ActionButton from "@/components/forms/ActionButton.vue";
@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,

  },
})
export default class PasswordLogin extends Vue {
  @Prop()
  public pnum?: string;
  @Prop()
  public email?: string;


  public passwordVisible: boolean = false;
  public input!: string;
  public hasError: boolean = false;
  public errorMessage: string = "";
  public isFormValid: boolean = false;
  public submitting: boolean = false;
  public inputChanged(newValue: any) {
    this.input = newValue.value;
    this.hasError = false;
    this.errorMessage = "";
  }

  public errorChanged(hasError: boolean) {
    this.isFormValid = !hasError;
  }
  public togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  public async continueClick() {
    this.submitting = true;
    const authResponse = await AuthService.Authenticate(AuthFlowContext.memberType, this.input, this.pnum, this.email);
    if (authResponse.authStatus === "failed") {
      this.hasError = true;
      this.errorMessage = this.$t("wrong-password").toString();
    } else if (authResponse.authStatus === "success") {
      const resp = await Authorize.redirectFlow(AuthFlowContext, authResponse.authToken);
      if (resp.internalRedirect) {
        this.$router.push({name: resp.internalRedirect, params: resp.internalRedirectProps});
      }
      return;
    } else throw new Error(`Unhandled response ${authResponse.authStatus} ${authResponse.code} ${authResponse.message} ${this.pnum} ${this.email}`);
    this.submitting = false;
  }
  public forgotPasswordClick() {
    this.$router.push({
      name: Routes.LostPasswordName,
      params: {
        pnum: this.pnum as string,
        email: this.email as string,
      },
    });
  }
}
