
import { Vue, Component } from "vue-property-decorator";
@Component
export default class MecenatLogo extends Vue {
  public get logoSrc() {
    const src = AuthFlowContext.memberType === "alumni" ? "/images/alumni-logo.svg" : 
    AuthFlowContext.memberType === "senior" ? "/images/seniordays-logo.svg" : "/images/logo.svg";
    
    return src;
  }

}
