
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import {EmailValidator} from "@/validation/EmailValidator";
import {PnumValidator} from "@/validation/PnumValidator";
import {PnumSanitizer} from "@/utilities/PnumSanitizer";
import ActionButton from "@/components/forms/ActionButton.vue";
import SelectBox from "@/components/forms/SelectBox.vue";
import Axios from "axios";
@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,
    SelectBox,
  },
})
export default class StudentApply extends Vue {
    @Prop(String) public pnum?: string;
    @Prop(String) public email?: string;
    @Prop(Boolean) public existing?: boolean;
    public emailValidator = EmailValidator.validate;
    public pnumValidator = PnumValidator.validate;

    public inputSchoolForm = "";
    public inputIGetCSN = false;
    public inputEmail: string = "";
    public inputPnum: string = "";
    public inputSchool: string = "";
    public inputFirstname: string = "";
    public inputLastname: string = "";
    public inputCity: string = "";
    public inputStreet: string = "";
    public inputCareOf: string = "";
    public inputPostalCode: string = "";
    public inputMessage: string = "";
    public errorMsg: string = "";


    private applicationSent: boolean = false;
    private inputFileUpload: any = "";
    private fileHasError: boolean = false;
    private fileErrorMsg: string = "";

    private sendingApplication: boolean = false;
    private emailBelongsToStudent: boolean = false;
  public availableSchoolForms() {
    return [
              {value: "", text: this.$t("pick-school-form").toString(), disabled: true, selected: true},
              {value: "highschool", text: this.$t("highschool").toString()},
              {value: "university", text: this.$t("university").toString()},
              {value: "folkuniversity", text: this.$t("folkuniversity").toString()},
              {value: "yh", text: this.$t("yh").toString()},
              {value: "komvux", text: this.$t("komvux").toString()},
              {value: "sfi", text: this.$t("sfi").toString()},
              {value: "commedu", text: this.$t("commedu").toString()},
              {value: "other", text: this.$t("other").toString()},
            ];
  }


/** Schools in this list (Except other) require you to join the union for a mecenatcard to be created, so we can't handle them through this application form */
  public availableSchools() {
    return [
              {value: "", text: this.$t("pick-school").toString(), disabled: true, selected: true},
              {value: "chalmers", text: this.$t("uni-chalmers").toString()},
              {value: "jonkoping", text: this.$t("uni-jonkoping").toString()},
              {value: "other", text: this.$t("other-schools").toString()},
    ];
  }
  public get showApplicationForm() {
     if (!this.inputSchoolForm) { return false; }

      const acceptedSchoolForms = ["highschool", "university", "folkuniversity", "yh", "komvux"];

      /*
      if (this.inputSchoolForm === "sfi") {
       const translatedSchool = this.$t("sfi").toString();
       this.errorMsg = ` ${this.$t("force-school", {school: translatedSchool})}`;
       return false;
     }*/
     
     // Check if chosen schoolform is in the accepted schoolforms list. If not show error.
     if (acceptedSchoolForms.indexOf(this.inputSchoolForm) === -1) {
      const translatedSchoolform = this.$t(this.inputSchoolForm).toString();
      this.errorMsg = ` ${this.$t("not-entitled-schoolform", {schoolform: translatedSchoolform})}`;
      return false;
    }

    // If shoolform is university and school has not been picked dont show application form
     if (this.inputSchoolForm === "university" && !this.inputSchool) {
      return false;
    }

     if (this.inputSchoolForm === "university" && this.inputSchool !== "other") {
      const translatedSchool = this.$t("uni-" + this.inputSchool).toString();
      this.errorMsg = ` ${this.$t("force-union-school", {school: translatedSchool})}`;
      return false;
    }

     this.errorMsg = "";
     return true;
  }

  public schoolFormPicked(schoolFormValue: string) {
    this.inputSchoolForm = schoolFormValue;
  }
  public schoolPicked(schoolValue: string) {
    this.inputSchool = schoolValue;
  }

  public continueClick() {
    this.sendingApplication = true;
    const formdata = new FormData();
    let ssn = this.pnum || this.inputPnum;
    ssn = PnumSanitizer.TenCharPnum(ssn);
    const email = this.email || this.inputEmail;
    const translatedSchoolform = this.$t(this.inputSchoolForm).toString();
    formdata.append("schoolform", translatedSchoolform);
    formdata.append("igetcsn", String(this.inputIGetCSN));
    formdata.append("ssn", ssn as string);
    formdata.append("email", email as string);
    formdata.append("firstname", this.inputFirstname as string);
    formdata.append("lastname", this.inputLastname as string);
    formdata.append("city", this.inputCity as string);
    formdata.append("address", this.inputStreet as string);
    formdata.append("co", this.inputCareOf as string);
    formdata.append("postalcode", this.inputPostalCode as string);
    formdata.append("message", this.inputMessage as string);
    formdata.append("files", this.inputFileUpload as string);
    
    if (this.inputSchool !== "other") {
      formdata.append("school", this.inputSchool as string);
    }
    formdata.append("userlang", this.$i18n.locale);

    const config = {
        headers: {"content-type": "multipart/form-data"},
    };
    
    this.sendingApplication = true;
    const uploadService = Axios.post("/2/api/card/studentapply", formdata, config)
    .then((result: any) => {
        if (result.data.code === 200) {
            this.applicationSent = true;
            this.sendingApplication = false;
        } else if((result.data.code === 413)) {
          this.emailBelongsToStudent = true;
          this.sendingApplication = false;
        } else {
          this.sendingApplication = false;
          this.errorMsg = this.$t("unknown-error").toString();
        }
    }, (err) => {
        this.sendingApplication = false;
        this.errorMsg = this.$t("unknown-error").toString();
    }).finally(() => {
      this.sendingApplication = false;
    })
  }
  public filesChange(a: any, b: any) {
      this.fileHasError = false;
      this.fileErrorMsg = "";
      const filesElem = document.getElementById("fileupload") as HTMLInputElement;
      const files = filesElem.files;
      if (!files) { return; }
      if (files.length < 1) { return; }
      const file = files[0];

      // dont forget to change on serverside
      const approvedExtensions = ["pdf", "odt", "ods", "jpg", "png", "bmp", "jpeg", "doc", "docx", "psd", "tif", "zip", "webp", "heic"];
      const fileExtension = file.name.toLowerCase().split(".").pop();
      if (approvedExtensions.indexOf(fileExtension as string) < 0) {
          this.fileHasError = true;
          this.fileErrorMsg = this.$t("student_apply:wrong_fileextension", this.$i18n.locale).toString();
          return;
      }
      const maxByteSize = 10000000; // 10mb

      if (file.size > maxByteSize) {
          this.fileHasError = true;
          this.fileErrorMsg = this.$t("student_apply:file_too_big", this.$i18n.locale).toString();
          return;
      }
      this.inputFileUpload = file;
  }
  public loginWithEmailClick(){
    this.$router.push({ name: "PasswordLogin", params: { email: this.email || this.inputEmail } });
  }
  private get fileUploadActive(): boolean {
    if(AuthFlowContext.appMajorVersion == 0) return true;

    if ((AuthFlowContext.appMajorVersion === 3 && AuthFlowContext.appMinorVersion >= 2) || (AuthFlowContext.appMajorVersion > 3)) return true;
    
    return !AuthFlowContext.isApp; // App currently doesnt support fileupload.
  }

  private get formValid() {
    const pnumExists = this.pnum || this.inputPnum;
    const emailExists = this.email || this.inputEmail;
    const userinfoExists = ((this.inputFirstname && this.inputLastname && this.inputStreet && this.inputCity && this.inputPostalCode) || this.existing);
    const formValid = pnumExists && emailExists && userinfoExists;

    if (!formValid) {
        return false;
    }
    // Only validate fileinput if its actually active
    if (this.fileUploadActive && !this.inputFileUpload) {
      return false;
    }

    return true;
  }
}
