import { AuthFlowContext } from "./context/AuthFlowContext";
import { MemberStatus, UserContext } from "./context/UserContext";
import { Routes } from "./router";
import { UrlHelper } from "./utilities/UrlHelper";
import jsCookie from "js-cookie";
import { WindowService } from "./services/window/window-service";
import { Tracking } from "./tracking/tracking";

export class Authorize {
    public static async validate(context: AuthFlowContext, userToken: string): Promise<AuthorizationResponse> {
        const response = new AuthorizationResponse();
        response.endOfFlowRedirect = Authorize.getEndOfFlowRedirect(context, userToken);
        const user = new UserContext(userToken);

        const scopeValidation: AuthValidationResponse = await Authorize.validateScope(context, user);
        if (!scopeValidation.valid) {
            response.valid = false;
            response.internalRedirect = scopeValidation.internalRedirect;
            response.internalRedirectProps = scopeValidation.internalRedirectProps;
            return response;
        }

        console.log("Scope validation passed, validating membertype");
        const memberTypeValidation: AuthValidationResponse = Authorize.validateMemberType(context.memberType as MemberStatus, user.role as MemberStatus);
        if (!memberTypeValidation.valid) {
            response.internalRedirect = memberTypeValidation.internalRedirect;
            return response;
        }

        console.log("Member type validation passed, validating consents");
        const consentValidation: AuthValidationResponse = await Authorize.validateConsent(context, user);
        if (!consentValidation.valid) {
            response.internalRedirect = consentValidation.internalRedirect;
            response.internalRedirectProps = consentValidation.internalRedirectProps;
            response.message = consentValidation.message;
            return response;
        }
        response.valid = true;
        return response;
    }

    public static async redirectFlow(context: AuthFlowContext, userToken: string): Promise<AuthorizationResponse> {
        const authorized = await Authorize.validate(context, userToken);
        if (userToken) {
            AuthenticatedUser = new UserContext(userToken);
            Authorize.setAuthCookie(AuthenticatedUser);
        }
        if (!authorized.valid) { return authorized; }
        //hj("tagRecording", ["completed"]);

        // After 1 second if the function hasn't been called already it will call it.
        const callback = Tracking.CreateFunctionWithTimeout(() => {
            Authorize.redirectFlowSuccess(authorized, context, userToken);
        }, 1000);

        gtag("event", "completed" , {event_category: "flow", event_label: "1",
            event_callback: callback,
        },
        );

        return authorized;
    }
    public static redirectFlowSuccess(authorized: AuthorizationResponse, context: AuthFlowContext, userToken: string): void {
        if (context.popup) {
            try {
                WindowService.PostMessageToOpener({ messageType: "ConnectSuccess", jsonWebToken: userToken }, context.redirectUrl);
                window.close();
                return;
            } catch (err:any) {
                window.location.href = authorized.endOfFlowRedirect as string;
            }
        }
        
        window.location.href = authorized.endOfFlowRedirect as string;
    }
    public static setAuthCookie(userContext: UserContext): void {
        if (!userContext.loggedIn) {
            return;
        }
        const cookieAttrs: jsCookie.CookieAttributes = {
            expires: new Date(Number(userContext.verificationValidTo) * 1000),
        };
        const temp = location.host.split(".").reverse();
        const rootDomain = "." + temp[1] + "." + temp[0];
        const rootCookieAttrs: jsCookie.CookieAttributes = {
            domain: rootDomain,
        };
        jsCookie.remove("mecenat.id", rootCookieAttrs);
        jsCookie.set("mecenat.id", userContext.rawJwt as string, cookieAttrs);
    }

    private static validateMemberType(requestedMemberStatus: MemberStatus, memberStatus: MemberStatus): AuthValidationResponse {
        const memberTypeAuth = new AuthValidationResponse();
        if (requestedMemberStatus.toLowerCase() === memberStatus.toLowerCase()) {
            memberTypeAuth.valid = true;
            return memberTypeAuth;
        }

        if (requestedMemberStatus === "alumni" && memberStatus === "student") {
            memberTypeAuth.internalRedirect = Routes.StudentTryingToAccessAlumniName;
            return memberTypeAuth;
        }

        if (requestedMemberStatus === "student" && memberStatus === "alumni") {
            memberTypeAuth.internalRedirect = Routes.AlumniTryingToAccessStudentName;
            return memberTypeAuth;
        }
        if (requestedMemberStatus === "senior" && (memberStatus === "student" || memberStatus === "alumni")) {
            throw new Error(`Requested member status Senior but got ${memberStatus}`);
            return memberTypeAuth;
        }
        return memberTypeAuth;


    }

    private static async validateScope(authContext: AuthFlowContext, user: UserContext): Promise<AuthValidationResponse> {
        const scopeValidationResponse = new AuthValidationResponse();
        const requestedScopes = authContext.scope;
        if(authContext.memberType === "senior"){
            scopeValidationResponse.valid = true;
            return scopeValidationResponse;
        }
        if (requestedScopes.indexOf("email") !== -1 && !user.email) {
            scopeValidationResponse.valid = false;
            scopeValidationResponse.message = "Email scope is required but missing email on user";
            scopeValidationResponse.internalRedirect = Routes.AccountInfoNeededName;
            return scopeValidationResponse;
        }
        /// Should get more school info.
        if (authContext.flow === "login" && !authContext.skipSchoolInformation && user.role === "student") {
            const schoolInfo = await UserService.GetSchoolInfo(user.uid as string);
            if (schoolInfo.code < 300 && (schoolInfo.isFieldOfStudyOutOfDate || !schoolInfo.fieldOfStudyId || !schoolInfo.graduationYear)) {
                scopeValidationResponse.valid = false;
                scopeValidationResponse.message = "schoolinfo needs to be checked";
                scopeValidationResponse.internalRedirect = Routes.SchoolInfoNeededName;
                scopeValidationResponse.internalRedirectProps = {schoolId: schoolInfo.schoolId};
                return scopeValidationResponse;
            }

        }

        scopeValidationResponse.valid = true;
        return scopeValidationResponse;
    }

    private static async validateConsent(context: AuthFlowContext, user: UserContext): Promise<AuthValidationResponse> {
        const consentValidationResponse = new AuthValidationResponse();
        if(context.memberType === "senior"){
            consentValidationResponse.valid = true;
            return consentValidationResponse;
        }
        const consents = await UserService.GetConsents(user.uid as string);
        if (!consents) { // Something went wrong let the person continue;
            console.error("couldnt fetch consents");
            consentValidationResponse.valid = true;
            return consentValidationResponse;
        }
        console.log(consents);
        if (consents.acceptedConsents.indexOf("consent_termsofservice") === -1) {
            consentValidationResponse.valid = false;
            consentValidationResponse.message = "User has not accepted terms of service consent";
            consentValidationResponse.internalRedirect = Routes.TermsAndConditionsName;
            return consentValidationResponse;
        }

        // Check if user has answered consent for profiling. Only ask for profiling consent if mecenat is the client.
        if (consents.answeredConsents.indexOf("consent_profiling") === -1 && context.clientId === "mecenat") {
            consentValidationResponse.valid = false;
            consentValidationResponse.message = "User has not answered profiling consent";
            let profilingLink = window.location.protocol + "//" + window.location.host + "/consent";
            if (context.memberType === "alumni") {
                profilingLink += "/alumni";
            }

            profilingLink = UrlHelper.addParamToUrl("userid", user.uid as string, profilingLink); // Dont use uid param here else old connect middleware will overwrite the jwt with old stuff.
            profilingLink = profilingLink.replace("http://localhost:8080", "https://localhost:44383");
            var callbackUrl = encodeURIComponent(window.location.href.replace(window.location.pathname, "/2"))
            profilingLink = UrlHelper.addParamToUrl("redirect_uri",callbackUrl , profilingLink);
            console.log("redirecting to profilingconsent", profilingLink);
            window.location.href = profilingLink;
            consentValidationResponse.valid = false;
            return consentValidationResponse;
        }
        consentValidationResponse.valid = true;
        return consentValidationResponse;
    }

    private static getEndOfFlowRedirect(context: AuthFlowContext, userToken: string) {
        let url = UrlHelper.addParamToUrl("mecenat_id", userToken, decodeURIComponent(context.redirectUrl));
        if (context.isApp) {
            try{
                const lastpath = location.pathname.substring(location.pathname.lastIndexOf("/")).replace("/", "");

                url = UrlHelper.addParamToUrl("from_view", lastpath, url);
            } catch (err:any) {
                console.error("failed to add from view");
            }

        }
        return url;
    }
}
// tslint:disable-next-line: max-classes-per-file
export class AuthorizationResponse {
    public valid: boolean = false;
    public internalRedirect?: string | null;
    public internalRedirectProps: any = {};
    public endOfFlowRedirect?: string | null;
    public message?: string | null;
}

// tslint:disable-next-line: max-classes-per-file
export class AuthValidationResponse {
    public valid: boolean = false;
    public internalRedirect?: string | null;
    public internalRedirectProps: any = {};
    public message?: string | null;
}
