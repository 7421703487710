export class EmailValidator {
  public static validate(value: string): boolean {
    const basicEmailRegex =
      /^(?!.*\.\.)[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/
    // validate email format
    if (!basicEmailRegex.test(value)) return false

    const invalidDomains = [
      'gamil.com',
      'gmai.com',
      'gmail.co',
      'gmial.com',
      'gnail.com',
      'hitmail.com',
      'hmail.com',
      'hormail.com',
      'hotamil.com',
      'hotmai.com',
      'hotmail.co',
      'hotmail.sr',
      'hotmail.ss',
      'hotmaill.com',
      'hotmal.com',
      'hotmial.com',
      'hotmil.com',
      'hotmsil.com',
      'hotnail.com',
      'icloud.co',
      'iclud.com',
      'icoud.com',
      'oitlook.com',
      'outllok.com',
      'outlok.com',
      'outloo.com',
      'outlook.co',
      'yaho.com',
    ]
    const domain = value.split('@')[1]
    // check if domain is invalid
    if (invalidDomains.includes(domain)) return false

    const invalidTlds = ['con', 'von', 'vom', 'cim', 'clm', 'cm', 'om', 'col']
    const tld = domain.split('.').pop()
    // check if tld is invalid
    if (invalidTlds.includes(tld!)) return false

    return true
  }
}
