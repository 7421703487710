import { UrlHelper } from "@/utilities/UrlHelper";


export class AuthFlowContext {

    /*** Defined from url */

    public clientId: string;
    public redirectUrl: string; /** If everything goes well we will redirect the user here. */
    public state: string; /** Optional: State id sent from the client, we will return this in the redirecturi. */

    /**
     *  What does the client want from the user, this will be available in the response and will force consent for client
     *  f.ex profile - will return firstname, lastname, gender, city
     *  email - will return email.
     */
    public scope: string[];
    /**
     * Which member type is the client after.
     * member - will return a userid of member regardless if its alumni, student or a regular member, will not force verification.
     * alumni - will return a userid of a mecenat alumni, will not let students pass and will force alumni verification from regular members.
     * student - will return a userid of a mecenat student and study expiration date, will force student verification from regular members and alumni.
     * Can be multiple separated by space f.ex "alumni student".
     */
    public memberType: string;

    /** If False the flow has a button to continue even without correct memberType. User should receive a warning that the redirectUrl site might not work as expected. */
    public forceMemberType: boolean;

    /** Code that will auto auth the user */
    public autoAuthCode?: string;

    /** source that refered user to this page */
    public referringSource?: string;

    /** Code/ID of the referring entity at the referringsource */
    public referringCode?: string;

    /** Which flow is it ? Login, Clickout, partner e.t.c ?  */
    public flow?: string;

    /**
     * Language that the client wants.
     */
    public lang: string;

    public popup: boolean;
    public isApp: boolean;

    /** App Version is only sent in app versions < 3.5 */
    public appMajorVersion: number = 0;
    public appMinorVersion: number = 0;
    public appPatchVersion: number = 0;

    /*** Entered by user during flow */
    public skipSchoolInformation?: boolean;

    public isHydraFlow?:boolean;
    constructor() {
        this.clientId = UrlHelper.getParamFromUrl("client_id", window.location.href) || "mecenat";
        this.state = UrlHelper.getParamFromUrl("state", window.location.href);
        this.referringSource = UrlHelper.getParamFromUrl("referring_source", window.location.href);
        this.referringCode = UrlHelper.getParamFromUrl("referring_code", window.location.href);
        this.scope = UrlHelper.getParamFromUrl("scope", window.location.href) ? UrlHelper.getParamFromUrl("scope", window.location.href).split(" ") : ["email"];
        this.memberType = UrlHelper.getParamFromUrl("member_type", window.location.href) ? UrlHelper.getParamFromUrl("member_type", window.location.href) : "student";
        this.lang = UrlHelper.getParamFromUrl("lang", window.location.href);
        this.isApp = UrlHelper.getBoolParam("is_app", window.location.href);
        this.popup = UrlHelper.getBoolParam("popup", window.location.href);
        this.forceMemberType = UrlHelper.getBoolParam("force_member_type", window.location.href);
        this.autoAuthCode = UrlHelper.getParamFromUrl("auto", window.location.href);
        
        this.skipSchoolInformation = UrlHelper.getBoolParam("skip_school", window.location.href);
        this.flow = UrlHelper.getParamFromUrl("flow", window.location.href) || "login";
        this.redirectUrl = this.getDefaultRedirectUrl();
        this.isHydraFlow = this.redirectUrl.toLowerCase().indexOf("/hydra/") !== -1;
        this.setAppVersionFromUrl();
    }
    public reset() {
        this.clientId = UrlHelper.getParamFromUrl("client_id", window.location.href) || "mecenat";
        this.state = UrlHelper.getParamFromUrl("state", window.location.href);
        this.referringSource = UrlHelper.getParamFromUrl("referring_source", window.location.href);
        this.referringCode = UrlHelper.getParamFromUrl("referring_code", window.location.href);
        this.scope = UrlHelper.getParamFromUrl("scope", window.location.href) ? UrlHelper.getParamFromUrl("scope", window.location.href).split(" ") : ["email"];
        this.memberType = UrlHelper.getParamFromUrl("member_type", window.location.href) ? UrlHelper.getParamFromUrl("member_type", window.location.href) : "student";
        this.lang = UrlHelper.getParamFromUrl("lang", window.location.href);
        this.isApp = UrlHelper.getBoolParam("is_app", window.location.href);
        this.popup = UrlHelper.getBoolParam("popup", window.location.href);
        this.forceMemberType = UrlHelper.getBoolParam("force_member_type", window.location.href);
        this.autoAuthCode = UrlHelper.getParamFromUrl("auto", window.location.href);
        this.flow = UrlHelper.getParamFromUrl("flow", window.location.href) || "login";
        this.redirectUrl = this.getDefaultRedirectUrl();
        this.setAppVersionFromUrl();

        this.skipSchoolInformation = UrlHelper.getBoolParam("skip_school", window.location.href);
    }
    private getDefaultRedirectUrl() {
        let redirectUrl = UrlHelper.getParamFromUrl("redirect_uri", window.location.href);
        if (!redirectUrl && this.memberType === "alumni") {
            redirectUrl = "https://www.mecenatalumni.com/se";
            return redirectUrl;
        }
        if (!redirectUrl && this.memberType === "senior") {
            redirectUrl = "https://www.seniordays.com/se";
            return redirectUrl;
        }
        if (!redirectUrl) {
            redirectUrl = "https://mecenat.com/se";
        }

        // If redirect is relative, we need support for relative redirects to mecenat.com because Apsis sucks and cant handle links with more than one occurrence of "http"
        if (redirectUrl[0] === "/") {

            let host = "https://mecenat.com";
            switch(this.memberType) {
                case "alumni":
                    host = "https://www.mecenatalumni.com";
                    break;
                case "student":
                    host = "https://mecenat.com";
                    break;
                case "senior":
                case "seniordays":
                    host = "https://www.seniordays.com";
                    break;
                default:
                    host = "https://mecenat.com";
            }
            redirectUrl = host + redirectUrl;
        }

        return redirectUrl;
    }
    private setAppVersionFromUrl() {
        const appVersion = UrlHelper.getParamFromUrl("app_version", window.location.href);
        if (!appVersion) {return; }

        const splitted = appVersion.split(".");
        if (splitted.length !== 3) { return; }
        try {
            this.appMajorVersion = Number(splitted[0]);
            this.appMinorVersion = Number(splitted[1]);
            this.appPatchVersion = Number(splitted[2]);
        } catch (err:any) {
            console.error(err);
        }
    }

}
