
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import Divider from "@/components/Divider.vue";
import StudentIdValidator from "@/validation/StudentIdValidator";
import { Authorize } from "@/authorize";
import { Routes } from "@/router";
import {PnumValidator} from "@/validation/PnumValidator";
@Component({
  components: {
    InfoBlock,
    MecInput,
    Divider,
  },
})
export default class PnumAuthentication extends Vue {
  @Prop()
  public pnum!: string;
  @Prop()
  public email?: string;
  public input!: string;
  public hasError: boolean = false;
  public errorMessage: string = "";
  public isFormValid: boolean = false;

  public validate: (v: string) => boolean = StudentIdValidator.validate;
  public useBankIdClick() {
    gtag("event", "verification submit" , {event_category: "verification", event_label: "bankid" });

    this.$router.push({ name: Routes.BankIdName, params: { email: this.email as string, pnum: this.pnum, redirectRouteName: Routes.AccountInfoNeededName, trackingName: "verification" } });
  }
  public useStudentIdClick() {
    const element = document.getElementById("studentid") as HTMLElement;
    element.focus();
  }

  public async inputChanged(newValue: any) {
    this.input = newValue.value;
    this.isFormValid = !newValue.hasError;
    this.hasError = false; // Resetting the overrides
    this.errorMessage = ""; // Resetting the overrides
    if (!newValue.hasError) {
      const response = await AuthService.AuthenticateWithStudentId(
        this.pnum,
        newValue.value,
      );

      if (response.code < 300) {
        const resp = await Authorize.redirectFlow(AuthFlowContext, response.authToken);

        this.$router.push({ name: resp.internalRedirect as string, params: resp.internalRedirectProps });
        return;
      } else if (response.code === 403) {
        this.hasError = true; // Overriding the formfield validation
        this.errorMessage = this.$t("wrong_studentid").toString(); // Overriding the formfield validation
        return;
      } else throw new Error(`Unhandled response ${response.code} ${response.message} ${newValue.value}`);
    }
  }
  public get showBankId() {
    return PnumValidator.validateNumeric(this.pnum);
  }
}
