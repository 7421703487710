export class MobilePhoneValidator {
    public static validate(value: string): boolean {
        const trimmed = MobilePhoneValidator.trimDashesAndSpaces(value);

        var reg = /(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/; 
        return reg.test(trimmed);
    }
    public static trimDashesAndSpaces(value: string){
        const newValue = value.replace(new RegExp("-", "g"), "").replace(new RegExp(" ", "g"), "");
        return newValue;
    }
}
