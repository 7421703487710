import { PnumSanitizer } from "@/utilities/PnumSanitizer";
import { PnumValidator } from "@/validation/PnumValidator";

///
export class SeniorValidator {
  /** Simple validator that only checks that first 6 are numbers and that there is total of 10. */
  public static validate(pnum: string): boolean {
    console.log("Validating"+pnum+" as senior" )
    let seniorPnumTenchar = PnumSanitizer.TenCharPnum(pnum);
    let seniorPnum = "19" + seniorPnumTenchar;
    var validPnum = PnumValidator.validate(seniorPnum);
    if (!validPnum) { return false; }

    const birthYear = seniorPnum.substring(0, 4);
    const birthMonth = seniorPnum.substring(4, 6);
    const birthDay = seniorPnum.substring(6, 8);
    const firsteight = birthYear +"-" +birthMonth +"-"+ birthDay;
    const age = SeniorValidator.getAge(firsteight);


    if(age < 55 && GlobalEnvironment.isOffice == false) return false;

    return true;
 
  }
  public static getAge(date:string) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
  
}
