import { GoogleAnalytics } from "./googleanalytics";
import { Hotjar } from "./hotjar";

export class Tracking {
    public static Init() {
        GoogleAnalytics.SetContext();
        Hotjar.SetContext();
    }
    public static TrackCurrentPageView(pageName: string, pagePath: string) {
        GoogleAnalytics.TrackCurrentPageView(pageName, pagePath);
    }

    /** Calls a function after timeout if it hasnt already been called. Useful for ensuring that a tracking request that fails still calls the callback function.
     * F.ex Google analytics has eventCallback property that calls a function when event submit is successful, altough it doesnt call it if it fails. 
     * This function ensures that the callback is called after 1 second if the trackingevent request fails.
     */
    public static CreateFunctionWithTimeout(callback: () => any, optTimeout: number ) {
        let called = false;
        function fn() {
          if (!called) {
            called = true;
            console.log("Timeout callback, will call now.");
            callback();
          }
        }
        setTimeout(fn, optTimeout || 1000);
        return fn;
      }
}
