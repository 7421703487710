
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBlock from "@/components/InfoBlock.vue";
import MecInput from "@/components/forms/MecInput.vue";
import { EmailValidator } from "@/validation/EmailValidator";
import { Routes } from "../router";
import i18n from "../i18n";
import { UserContext } from "@/context/UserContext";

import ActionButton from "@/components/forms/ActionButton.vue";
@Component({
  components: {
    InfoBlock,
    MecInput,
    ActionButton,
  },
})
export default class LostPassword extends Vue {
  @Prop()
  public email?: string;

  @Prop()
  public secondaryEmail?: string;
  @Prop()
  public pnum?: string;

  public resetMethod: string = "email";
  public maskedEmail: string = "";
  public maskedSecondaryEmail: string = "";
  public enterCodeStep: boolean = false;
  private resetCode: string = "";
  private codeHasError: boolean = false;
  private submittingResetCode: boolean = false;
  public async continueClick() {
    if (this.resetMethod === "bankid") {
      gtag("event", "reset password submit" , {event_category: "reset password", event_label: "bankid"});
      this.$router.push({name: Routes.BankIdName, params: {email: this.email as string, pnum:  this.pnum as string, redirectRouteName: Routes.ChangePasswordName, trackingName: "reset password"}});
    } else {
      this.resetPasswordRequest();
    }
  }
  public async resetCodeClick()  {
    this.submittingResetCode = true;
    const sendToSecondaryEmail = this.resetMethod === "secondary";
    gtag("event", "reset passwordcode submit" , {event_category: "reset password", event_label: this.resetMethod});
    const response = await UserService.ValidateResetPasswordCode(this.resetCode,AuthFlowContext, this.pnum, this.email, sendToSecondaryEmail);
    if (response.code < 300) {
      gtag("event", "reset passwordcode success" , {event_category: "reset password", event_label: this.resetMethod});
      AuthenticatedUser = new UserContext(response.authToken);
      // Go to resetpassword view.
      this.$router.push({
          name: Routes.ChangePasswordName,
        });
    } else if (response.code === 403) {
      gtag("event", "reset failed success" , {event_category: "reset password", event_label: this.resetMethod});
      this.codeHasError = true;
      
    } else throw new Error(`Unhandled response ${response.code} method: ${this.resetMethod} tosec:${sendToSecondaryEmail} p:${this.pnum} e:${this.email} s:${this.secondaryEmail}`);
    this.submittingResetCode = false;

  }
  public resetCodeChanged(newValue: any) {
    this.resetCode = newValue.value;
  }
  public async resetPasswordRequest() {
    const sendToSecondaryEmail = this.resetMethod === "secondary";
    gtag("event", "reset password submit" , {event_category: "reset password", event_label: this.resetMethod});
    // User will be sent to this url alongside uid parameter to change his/hers password if they click the link.
    const callbackUrl = window.location.origin + "/2" + Routes.ChangePasswordPath;
    const response = await UserService.ResetPasswordRequest(
      callbackUrl,
      i18n.locale,
      AuthFlowContext,
      this.pnum,
      this.email,
      this.secondaryEmail,
      sendToSecondaryEmail,
    );
    if (response.code < 300) {
      this.enterCodeStep = true;
    } else throw new Error(`Unhandled response ${response.code} method: ${this.resetMethod} tosec:${sendToSecondaryEmail} p:${this.pnum} e:${this.email} s:${this.secondaryEmail}`);
  }
  public async handlePnumResetPassword() {
    console.log("getting masked email");
    const response = await UserService.GetMaskedEmail(this.pnum as string, AuthFlowContext.memberType);
    if (response.code < 300) {
      if (response.primaryEmail) this.maskedEmail = response.primaryEmail;
      if (response.secondaryEmail) this.maskedSecondaryEmail = response.secondaryEmail;

    } else throw new Error(`Unhandled response ${response.code} when getting masked email method: p:${this.pnum} e:${this.email} s:${this.secondaryEmail}`);

  }
  public created() {
    if (!this.email && this.pnum) {
      this.handlePnumResetPassword();
    }
  }
  public get showBankIdReset() {
    if (!this.pnum || AuthFlowContext.memberType == "senior") { return false; }
    const isNum = /^\d+$/.test(this.pnum);
    return isNum; // Only allow pure numbers to bankId;
  }
}
