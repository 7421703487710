
import { Prop, Vue, Component } from "vue-property-decorator";
@Component({

})
export default class ConsentPage extends Vue {
private memberType:string ="";
  get isSenior(){
			return this.memberType == "senior";
		}

		get isAlumni(){
			return this.memberType == "alumni";
		}
}
